import React from 'react';
import {
  SimpleShowLayout,
  ShowController,
  Show
} from 'react-admin';
import {
  Typography,
  IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import AnnoncesTitle from './AnnoncesTitle'
import AnnoncesActions from './AnnoncesActions';
import AnnoncesDetailsPanel from './details/AnnoncesDetailsPanel';
import AnnoncesPicturesPanel from './pictures/AnnoncesPicturesPanel';
import AnnoncesGaragePanel from './garage/AnnoncesGaragePanel';
import AnnoncesGroupPanel from './groupe/AnnoncesGroupPanel';
import AnnoncesTheoricalOffersPanel from './theoriques/AnnoncesTheoricalOffersPanel';
import AnnoncesTwinOffersPanel from './twins/AnnoncesTwinOffersPanel';

import BackButton from '../template_components/BackButton';

const useStyles = {
  root: {
    "paddingTop": "40",
    "flexGrow": "1",
  },
  layout: {
    "flexGrow": 1,
    ["theme.breakpoints.up('xs')"]: {
      "width": "500",
    },
    ["theme.breakpoints.down('xs')"]: {
      "width": '100vw',
      "marginTop": "-30",
    },
  },
  title: {
    "display": 'flex',
    "alignItems": 'center',
    "justifyContent": 'space-between',
    "margin": "24",
  },
};

const AnnoncesShow = () => {
  return (
    <ShowController>
      {controllerProps =>
        controllerProps.record ? (
          <div className={useStyles.root}>
            <div className={useStyles.title}>
              <Typography variant="h6">
                <AnnoncesTitle title="Annonce Title" record={controllerProps.record} />
              </Typography>
              {/*<IconButton href="#/offers_all">
                  <CloseIcon />
                  </IconButton>*/}
              <BackButton >
                <CloseIcon />
              </BackButton>
            </div>
            <AnnoncesActions record={controllerProps.record} />
            <Show
              title={<AnnoncesTitle record={controllerProps.record} />}
              className={useStyles.layout}
              basePath={controllerProps.basePath}
              record={controllerProps.record}
              version={controllerProps.version}
              resource="offers_all"
              {...controllerProps}
            >
              {/* <AnnoncesDetailsPanel /> */}
              {/* <AnnoncesPicturesPanel /> */}
              {/* <AnnoncesTheoricalOffersPanel /> */}
              {/* <AnnoncesTwinOffersPanel /> */}
              {/* <AnnoncesGaragePanel /> */}
              {/* <AnnoncesGroupPanel /> */}
            </Show>
          </div>
        ) : null
      }
    </ShowController>
  );
}

export default AnnoncesShow;
