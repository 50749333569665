import React, { Component, Fragment } from 'react';
import {
  ReferenceArrayField,
  ReferenceManyField,
  ReferenceField,
  NumberField,
  BooleanField,
  TextField,
  // DateField,
  SingleFieldList,
  Datagrid,
  List,
  Pagination,
  DateField,
  useRecordContext,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import style from '@mui/system/style';
import Drawer from '@mui/material/Drawer';
import PriceField from '../../template_components/PriceField';
import DistanceField from '../../template_components/DistanceField';
import Carousel from '../../template_components/Carousel';
import ProjectOffersListActions from './ProjectOffersListActions';
import ProjectOffersBulkActionButtons from './ProjectOffersBulkActionButtons';
import ProjectOffersShow from './ProjectOffersShow';
import ProjectOffersTitle from './ProjectOffersTitle';
import ProjectOffersFilters from './ProjectOffersFilters';
import ProjectOffersRowButtons from './ProjectOffersRowButtons';

const styles = theme => ({
  drawerPaper: {
    height: 'calc(100% - 197px)',
    top: 197,
    position: 'absolute'
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 517,
  },
  progress: {
    maxWidth: 24,
    overflow: 'hidden',
  },
  header: {
    maxWidth: 71,
  },
});

const dataRowStyle = record => ({
  backgroundColor: record.user_validation === true ? '#2ecc71' : 'white',
});

let lastScrollY = 0;
let ticking = false;

const ProjectOffersList = () => {
  const locate = useLocation();
  const getRecord = () => {
    return useRecordContext();
  }

  const getListContext = () => {
    return useListContext();
  }

  const state = {
    open: false,
    selected: null,
    style: {
      top: 197,
      position: 'absolute',
      height: 'calc(100% - 197px)',
    },
  };

  const handleClick = (id, basePath, record) => {
    if (state.selected === id) setState({ open: false, selected: null });
    else setState({ open: true, selected: id });
  };

  const handleClose = () => {
    setState({ open: false, selected: null });
  };

  const handleScroll = () => {
    lastScrollY = window.scrollY;
    if (!ticking) {
      window.requestAnimationFrame(() => {
        if (lastScrollY > 197 && state.style.top === 197) {
          setState({
            style: {
              top: 0,
              position: 'fixed',
              height: '100%',
            }
          });
        } else if (lastScrollY <= 197 && state.style.top === 0) {
          setState({
            style: {
              top: 197,
              position: 'absolute',
              height: 'calc(100% - 197px)',
            }
          });
        }
        ticking = false;
      });
      ticking = true;
    }
  }

  const componentDidMount = () => {
    window.addEventListener('scroll', handleScroll, true);
  }

  const componentWillUnmount = () => {
    window.removeEventListener('scroll', handleScroll);
  }


  return (
    <Fragment>
      <ReferenceArrayField
        // className={classnames(classes.list, {
        //   [classes.listWithDrawer]: state.open,
        // })}
        addLabel={false}
        source="ids_reconciliation"
        reference="reconciliation"
        onScroll={handleScroll}
      >
        <List
          title={<ProjectOffersTitle />}
          exporter={false}
          actions={<ProjectOffersListActions record={getRecord} />}
          filters={<ProjectOffersFilters />}
          filter={{ id: getRecord ? getRecord.ids_reconciliation : [] }}
          sort={{
            field: `row_updated`,
            order: 'DESC',
          }}
          perpage={25}
          pagination={<Pagination rowsPerPageOptions={[25, 50, 75, 100]} />}
          resource={"reconciliation"}
          location={locate}
          // hascreate={false}
          hasEdit={true}
          hasShow={true}
          hasList={true}
        >
          <Datagrid
            // rowStyle={dataRowStyle}
            rowClick={handleClick}
          >
            <Carousel label="Images" source="detail.images" />
            <TextField label="Marque" source="detail.marque" />
            <TextField label="Modèle" source="detail.modele" />
            <TextField label="Garage" source="detail.garage" />
            <TextField label="Année" source="detail.annevehicule" />
            <PriceField label="Prix" source="detail.prix" />
            <DistanceField label="Kilométrage" source="detail.km" />
            <TextField label="Code Postal" source="detail.codepostal" />
            <DateField label="Date" source="row_updated" showTime />
            <ReferenceField
              label="Fiche technique"
              source="id"
              reference="datasheets_link"
              link={false}
              sortable={false}
            // headerClassName={classes.header}
            // cellClassName={classes.progress}
            >
              <BooleanField source="valide" />
            </ReferenceField>
            {/* <ReferenceManyField label="Affectée le" reference="projects_reconciliations" target="id_reconciliation" filter={{ id_project: props.record.id }} perPage={1} linkType={false}>
                <SingleFieldList>
                  <DateField source="row_inserted" />
                </SingleFieldList>
              </ReferenceManyField> */}
            <ReferenceManyField label="Choix client" reference="vprojects_reconciliations_withreplies" target="id_reconciliation" filter={{ id_project: getRecord.id }} perpage={1} linkType={false}>
              <SingleFieldList>
                <NumberField source="place" />
              </SingleFieldList>
            </ReferenceManyField>
            <ProjectOffersRowButtons project_id={getRecord && getRecord.id} />
          </Datagrid>
        </List>
      </ReferenceArrayField>
      <Drawer
        variant="persistent"
        open={state.open}
        anchor="right"
        onClose={handleClose}
        // classes={{
        //   paper: classes.drawerPaper,
        // }}
        PaperProps={{
          style: state.style,
        }}
      >
        {state.open ?
          <ProjectOffersShow
            project_id={getRecord && getRecord.id}
            id={state.selected}
            resource={"reconciliation"}
            basePath={"/reconciliation"}
            open={state.open}
            onClose={handleClose}
          />
          : null}
      </Drawer>
    </Fragment >
  )
};

export default ProjectOffersList;

