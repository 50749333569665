export const getTinderformMail = (user, op) => `<p>Bonjour ${user.name},</p>
<p>
Une nouvelle fois, un grand merci pour la confiance que vous nous accordez.
</p>
<p>
Vous trouverez ci-dessous un lien vers une page dédiée à votre projet. L'objectif de cette étape :
</p>
<ul>
<li>identifier toutes les alternatives qui pourraient vous plaire</li>
<li>appréhender l'intégralité des paramètres de votre recherche pour ne vous présenter que des véhicules pertinents</li>
<li>planifier un bref entretien téléphonique pour lever les dernières interrogations sur votre projet</li>
</ul>
<p>
Nous irons ensuite chercher votre perle rare parmi les plus de 800 000 annonces présentes sur le marché.
</p>
<p>
Pour découvrir les différentes alternatives que nous avons identifiées, <b><a href="${user.typeform}">cliquez ici</a></b>.
</p>
<p>
Bien cordialement.

<table style="font-family: &quot;montserrat&quot;, helvetica, arial, sans-serif; color: rgb(0, 0, 0); font-size: 11px" border="0">
    <tbody>
        <tr>
            <td style="padding-left: 0px; padding-top: 10px; padding-bottom: 0px; text-decoration: none">
                <a style="text-decoration: none" href="http://www.car-expresso.com">
                    <img height="40" width="139" alt="car-expresso-logo" src="https://car-expresso.com/images/logo-car-expresso.png?tid=${ user.tracked_id }">
                </a>
                <br>
            </td>
        </tr>
        <tr>
            <td style="padding-left: 34px; padding-top: 0px; text-decoration: none">
                <div>
                    <b>
                        ${op.firstname} ${op.lastname}
                    </b>
                    <br>
                </div>
                <div>
                    ${op.role}
                    <br>
                </div>
                <div>
                    ${op.phone}
                    <br>
                </div>
                <div>
                    <b>
                        <a style="text-decoration: none; color: rgb(0, 0, 0)" href="https://www.car-expresso.com/" target="_blank">
                            www.car-expresso.com
                        </a>
                    </b>
                    <br>
                </div>
            </td>
        </tr>
    </tbody>
</table>
<div>
    <br>
</div>
<div id="_resizecolDivs">
    <div class="ze_colResize" id="tabLeft" style="top: 15px; width: 3px; height: 112.667px; left: 15px;">
    </div>
    <div class="ze_colResize" id="tabRight" style="top: 15px; width: 3px; height: 112.667px; left: 187px;">
    </div>
    <div class="ze_rowResize" id="rowTop" style="position: absolute; left: 15px; width: 172px; top: 14px; height: 3px;">
    </div>
    <div class="ze_rowResize" id="0" style="position: absolute; left: 15px; width: 172px; top: 70.6667px; height: 3px;">
    </div>
    <div class="ze_rowResize" id="rowBottom" style="position: absolute; left: 15px; width: 172px; top: 126.667px; height: 3px;">
    </div>
</div>
`
