import React from 'react';
import {Button, Link, useRecordContext } from 'react-admin';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CreateIcon from '@mui/icons-material/Create';
import SearchIcon from '@mui/icons-material/Search';
import RedditIcon from '@mui/icons-material/Reddit';

import ScraperLaunchButton from './components/rowButtons/ScraperLaunchButton';
import UpdateStatsButton from './components/rowButtons/UpdateStatsButton';
import CustomDeleteNeedsSearchButtonButton from "../../template_components/CustomDeleteNeedsSearchButton";

const ResearchRowButtons = (props) => {
    // console.log(props);
    // console.log("RESEARCH ROW BUTTONS HIT");
    return(
      <>
        {/* <ScraperLaunchButton {...props} />
        <BestOffersButton {...props} />    
        <SearchOffersButton {...props} />
        <UpdateStatsButton {...props} />
        <AddFetchSearchButton {...props} />
        <EditSearchButton {...props} /> */}
        <CustomDeleteNeedsSearchButtonButton {...props}/> 
      </>
  );
}

const AddFetchSearchButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/needs_search/create',
      search: `?source=${JSON.stringify({ project_id: record.project_id })}`,
      state: {
        record: {
          project_id: record.project_id,
          need_id: record.need_id,
          annee: record.annee,
          marque: record.marque,
          modele: record.modele,
          prixmin: record.prixmin,
          prixmax: record.prixmax,
					max_km: record.max_km,
          carburant: record.carburant,
          boite: record.boite,
          segments: record.segments,
          keywords: record.keywords,
        }
      }
    }}
    label=""
    color="secondary"
  >
    <FileCopyIcon />
  </Button>
);

const EditSearchButton = ({ record }) => (
  <Button
    //component={Link}
    to={{
      pathname: `/needs_search/${record.id}`,
      search: `?project_id=${record.project_id}`,
    }}
    label=""
    color="secondary"
  >
    <CreateIcon />
  </Button>
);

const SearchOffersButton = ({ record }) => (
  <Button
    //component={Link}
    to={`/offers_all?filter={"existe":true,"json_propre":{"sure":{`
    + (record.modele === null ? '' : `"modele":["${record.modele}"],`)
    + (record.annee === null ? '' : `"annee_modele":{"gte":${record.annee},"lte":${record.annee}},`)
    + (record.prixmin === null || record.prixmax === null ? '' : `"prix":{"gte":${record.prixmin},"lte":${record.prixmax}},`)
    + (record.max_km === null ? '' : `"kilometrage":{"lte":${record.max_km}},`)
    + ((Array.isArray(record.boite) && record.boite.length) ? `"boite_de_vitesses":[${record.boite.map(value => '%22'+value+'%22').join(',')}],` : '')
    + ((Array.isArray(record.carburant) && record.carburant.length) ? `"carburant":[${record.carburant.map(value => '%22'+value+'%22').join(',')}],` : '')
    + ((Array.isArray(record.keywords) && record.keywords.length) ? `"description":{"ilike":[${record.keywords.map(value => '%22'+value+'%22').join(',')}]},` : '')
    + `"marque":["${record.marque}"]`
    + `}}}&order=ASC&page=1&perPage=25&sort=json_propre['sure']['date_publication']`}
    label=""
    color="secondary"
  >
    <SearchIcon />
  </Button>
);

function journeyTime (days) {
  let now = new Date();
  now.setDate(now.getDate() +days);
  return now
}

const BestOffersButton = ({ record }) => {

  return(
    <Button
      //component={Link}
      to={`/rpc/ce_best_offers?filter={`
      + `"marque@":["${record.marque}"],`
      + (record.modele === null ? '' : `"modele@":["${record.modele}"],`)
      + ((Array.isArray(record.carburant) && record.carburant.length) ? `"carburant@":[${record.carburant.map(value => '%22'+value+'%22').join(',')}],` : '')
      + ((Array.isArray(record.boite) && record.boite.length) ? `"boite_de_vitesses@":[${record.boite.map(value => '%22'+value+'%22').join(',')}],` : '')
      + (record.prixmin === null ? '' : `"minbudget@":${record.prixmin},`)
      + (record.prixmax === null ? '' : `"maxbudget@":${record.prixmax},`)
      + (record.prixmin === null ? '' : `"journeytime@":120,`)
      + (record.postal_code === null ? '' : `"zipcode@":${record.postal_code},`)
      + (`"updateddate@":"${journeyTime(-7).toISOString().split('T')[0]}",`)
      + `"garantie_std":true,"puissance_std":true,"kilometrage_std":true,"annee_std":true`
       + `}&order=ASC&page=1&perPage=50&sort=id`}
      label=""
      color="secondary"
    >
      <RedditIcon />
    </Button>
  );
}
 
export default ResearchRowButtons;
