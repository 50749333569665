import React, { Component } from 'react';
import BugReportIcon from '@mui/icons-material/BugReport';
import { PG_URL } from '../../../../constantes/Utils';
import OutlinedButton from '../../../../template_components/OutlinedButton';

class BulkLaunchScraperButton extends Component {
	constructor(props) {
		super(props);
    this.state = { 
			loading: false,
			error: false,
			success: false,
		};
		this.handleClick = this.handleClick.bind(this)
	}

	handleClick() {
		this.setState({ loading: true, error: false, success: false });
		
		var urlApi = `${PG_URL}/rpc/launch_projects_scrapper`;

		const payload = {
			ids: '{'+this.props.selectedIds.toString()+'}',
		}

		const body = JSON.stringify(payload)

		fetch(urlApi, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: body})
		.then(response => {
			console.log(response)
			this.setState({ loading: false, error: false, success: true });
		}).catch(err => {
			console.error(err)
			this.setState({ loading: false, error: true });
		})
	}

	render() {
		const { state } = this;
		const { loading, error, success } = state;
		
		return (
			<OutlinedButton
				icon={<BugReportIcon />}
				loading={loading}
				success={success}
				error={error}
				onClick={this.handleClick}
				label={'Lancer le scraper'}
				successLabel={'Scraper lancé !'}
				loadingLabel={'Lancement du scraper...'}
			/>
		)
	}
}

export default BulkLaunchScraperButton;