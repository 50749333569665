import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { style } from '@mui/system';
import { Link } from 'react-admin';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { PG_URL } from '../../constantes/Utils';
import { green } from '@mui/material/colors';

const styles ={
  root: {
    flex: 1,
  },
  main: {
    //...theme.mixins.gutters(),
    display: 'inline-block',
    maxWidth: '400px',
    paddingBottom: "3rem",
    marginLeft: '20px',
  },
  card: {
    padding: '16px 0',
    overflow: 'inherit',
    textAlign: 'right',
  },
  title: {
    padding: '0 16px',
  },
  value: {
    padding: '0 16px',
    minHeight: 48,
  },
  listItemText: {
    paddingRight: 0,
    color: 'black',
    textTransform: 'None'
  },
  row_inserted: {
    marginRight: '1em',
    color: 'black',
  },
  listItem:{
    backgroundColor: 'red',
  }
};

const NewProjectsPanel2 = () => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     projects: [],
  //   };
  // }

  const [state, setState] = useState({
    projects: []
  });
  

  const componentDidMount = () => {
    const myHeaders = new Headers();
    myHeaders.append('Range', '0-14');
    const urlApiProj = `${PG_URL}/vprojects_reconciliations?active=eq.true&follow_by=is.null&select=id,name,budget_max,row_inserted,agency,follow_by`;

    fetch(urlApiProj, { headers: myHeaders }).then(response => response.json())
      .then((data) => {
        setState({ projects: data });
      });
  }
  useEffect(() => {
  componentDidMount()
  }, [])


  // render() {
  //   const { props: propsVal, state: stateVal } = this;
  //   const { classes: classesVal } = propsVal;
  //   const { projects } = stateVal;

    return (
      <div>
      {/* <Card className={classesVal.root}> */}
      <Card style={{padding:"0.5em 1.5em"}}>
        {/* <Typography
          variant="h5"
          component="h4"
          className={classesVal.value}
        > */}
        <Typography
          variant="h5"
          component="h4"
        >
          <PersonAddIcon sx={{color: green[500]}}/>
          &nbsp;
          <span className='panel-title'>Nouveaux projets (non attribués)</span>
        </Typography>
        <Divider />
        <List dense={true}>
          {(Array.isArray(state.projects) && state.projects.length) ? state.projects.map(record => (
            <ListItem
                component={Button}
                href={`/vprojects_reconciliations/${record.id}/show`}
                key={record.id}
                sx={{
                  width: '100%',
                  padding:"0px 0.5em", 
                  marginBottom: "0.5em",
                  ":hover": {backgroundColor:green[500], color: 'white'}
                }}
              >
              {/* <ListItemText
                primary={`${record.name} (${record.agency})`}
                className={classesVal.listItemText}
                secondary={`Projet créé le ${(new Date(record.row_inserted)).toLocaleString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit'})} à ${(new Date(record.row_inserted)).toLocaleString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`}
              /> */}
              <ListItemText
                primary={`${record.name} (${record.agency})`}
                secondary={`Projet créé le ${(new Date(record.row_inserted)).toLocaleString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit'})} à ${(new Date(record.row_inserted)).toLocaleString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`}
                class='panel-text'
              />
              <ListItemText sx={{ textAlign: 'right', fontSize: "1em" }} primary={'Budget :'} secondary={`${record.budget_max}€`}/>
            </ListItem>
          )) : null}
        </List>
      </Card>
      </div>
    );
  // }
}

export default NewProjectsPanel2;
