import React, { Fragment, useEffect } from 'react';
import { Link, Button, useUnselectAll, BulkExportButton } from 'react-admin';
import AddBoxIcon from '@mui/icons-material/AddBox';

import BulkSearchButton from './components/bulkButtons/BulkSearchButton';
import BulkCreateTypeformButton from './components/bulkButtons/BulkCreateTypeformButton';
import BulkUpdateStatsButton from './components/bulkButtons/BulkUpdateStatsButton';
import BulkLaunchScraperButton from './components/bulkButtons/BulkLaunchScraperButton';
import CustomBulkDeleteNeedsSearchButtonButton from "../../template_components/CustomBulkDeleteNeedsSearchButton";

const ResearchBulkActionButtons = (props) => {

	const unselectAll = useUnselectAll();

	useEffect(() => { // to unselect all after component is unmounted

		return () => {
			// Anything in here is fired on component unmount.
			unselectAll(props.resource);

		}
	}, [unselectAll, props.resource])

	return (
		<Fragment>
			<BulkExportButton>
				<BulkUpdateStatsButton {...props} />
				<BulkCreateTypeformButton {...props} />
				<BulkSearchButton {...props} />
				<BulkLaunchScraperButton {...props} />
				<BulkCreateNeedButton {...props} />
				<CustomBulkDeleteNeedsSearchButtonButton {...props} />
			</BulkExportButton>

		</Fragment>)
};

const BulkCreateNeedButton = ({ project_id, selectedIds }) => (
	<Button
		//component={Link}
		to={{
			pathname: `/needs/create`,
			search: `?source=${JSON.stringify({ project_id: project_id, researches: selectedIds })}`,
		}}
		label="Créer un besoin"
		color="secondary"
		variant="outlined"
	>
		<AddBoxIcon />
	</Button>
);

export default ResearchBulkActionButtons;
