import React from 'react';
import {
  Tab,
  TabbedShowLayout,
  useRecordContext,
} from 'react-admin';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ProjectOffersList from './ProjectOffersList';

const ProjectOffersTab = ({ hasEdit, hascreate, hasList, hasShow }) => (
  <TabbedShowLayout.Tab label="Annonces assignées">
    <ProjectOffersList />
  </TabbedShowLayout.Tab>
);

export default ProjectOffersTab;
