import React from 'react';
import {
  useRecordContext,
} from 'react-admin';

const AnnoncesTitle = ({record}) => {
  document.title = 
  record ? `Annonces - ${record.json_propre.sure.marque} ${record.json_propre.sure.modele} ${record.json_propre.sure.carburant} ${record.json_propre.sure.annee_modele}`
  : 'Annonces'

	return (
    <span>
      {document.title}
    </span>
	);
};

export default AnnoncesTitle;
