import React from 'react';
import { style } from '@mui/system';
import CustomTextField from '../../../template_components/CustomTextField';
import { useRecordContext } from 'react-admin';

const useStyles = style({
  root: {
    padding: 0,
    maxWidth: '100%',
  },
});

const AnnoncesDetails = () => {
  const classes = useStyles();
  const record = useRecordContext();
  
  const descriptionArrayToString = (desc) => {
    let ret = '';
    if (desc) {
      desc.forEach((value) => {
        ret += value + '\n';
      });
    }
    return ret;
  };

  if (!record || !record.json_propre || !record.json_propre.sure) {
    return <div />;
  }

  const betterJson = record.json_propre.sure;

  return (
    <div className={classes.root}>
      {typeof betterJson.description === 'string' ? (
        <CustomTextField
          title="Description"
          value={betterJson.description.split('\n').map((item, i) => (
            <span key={i}>
              {item}
              <br />
            </span>
          ))}
        />
      ) : (
        <CustomTextField
          title="Description"
          component="pre"
          value={Object.entries(betterJson.description).map((item, i) => (
            <span key={i}>
              {item[0]} : {descriptionArrayToString(item[1])}
              <br />
            </span>
          ))}
        />
      )}
      <CustomTextField title="Titre" value={betterJson.titre} />
      <CustomTextField title="Portes" value={betterJson.portes} />
      <CustomTextField title="Carburant" value={betterJson.carburant} />
      <CustomTextField title="Boîte de vitesses" value={betterJson.boite_de_vitesses} />
      <CustomTextField title="Code Postal" value={String(betterJson.code_postal)} />
    </div>
  );
};

export default AnnoncesDetails;

