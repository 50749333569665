import React from 'react';
import {
  TopToolbar,
} from 'react-admin';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';
import ReconciliationButton from './components/ReconciliationButton';
import AnnoncesGroupsBlacklistButton from './components/AnnoncesGroupsBlacklistButton';
import AssignOfferButton from './components/AssignOfferButton';

const useStyles = {
  root: {
    "margin": "24",
    "marginTop": "-100",
    "display": 'inline-flex',
    "maxWidth": "500",
  },
};

const AnnoncesActions = ({ record }) => (
  <TopToolbar className={useStyles.root}>
    <ReconciliationButton record={record} />
    {/* <AnnoncesGroupsBlacklistButton record={record} /> */}
    {/* <SeeOfferButton record={record} /> */}
    <AssignOfferButton record={record} />
  </TopToolbar>
);

const SeeOfferButton = props => (
  <div>
    <Button variant="outlined" style={{ marginBottom: 8, padding: '0.4em 0.5em', }} color="secondary" href={props.record.json_propre.sure.url} target="_blank">
      <LaunchIcon />
      &nbsp;Annonce
    </Button>
  </div>
);

export default AnnoncesActions;
