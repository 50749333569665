import React, { Component } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

//
import PendingProjectsPanel from './components/PendingProjectsPanel';
import NewProjectsPanel from './components/NewProjectsPanel';
import NewProjectsPanel2 from './components/NewProjectsPanel2';
import CurrentProjectsPanel from './components/CurrentProjectsPanel.jsx';
import TaskslistPanel from './components/TaskslistPanel';
import { bgcolor, display } from '@mui/system';
import "./dashboard.css"

{/*
const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    display: 'inline',
    paddingBottom: theme.spacing(2) ,
    padding: 20,
  },
});
*/}



const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nbr_ann: 0,
    };
  }

  componentDidMount() {
    document.title = "Car-Expresso Dashboard"
    const myHeadersOffr = new Headers();

    myHeadersOffr.append('Prefer', 'count=exact');
    myHeadersOffr.append('Range', '0-0');

  }

  render() {
    const { props: propsVal } = this;
    const { classes: classesVal } = propsVal;

    {/*}
    return (
      <Paper className={classesVal.root}>
        <Typography variant="h4" gutterBottom>
          Car-Expresso Dashboard 3.0
        </Typography>
        <Divider />
        <br />
        <div style={styles.flex}>
          <CurrentProjectsPanel />
          <PendingProjectsPanel />
          <NewProjectsPanel />
          <div style={styles.rightCol}>
            <div style={styles.flex}>
              <NewProjectsPanel2 />
            </div>
          </div> 
          <Spacer />
          <div style={styles.rightCol}>
            <div style={styles.flex}> 
              <TaskslistPanel />
            </div>
          </div>
        </div>

      </Paper>
    );
    */}

    const styles = {
      flex: { display: 'flex', gap: '3em', marginBottom: '3em'},
      flexColumn: { display: 'flex', flexDirection: 'column' },
      leftCol: { flex: 1, marginRight: '0.5em' },
      rightCol: { flex: 1, marginLeft: '0.5em' },
      singleCol: { marginBottom: '1em', marginTop: "1em" },
    };

    return (
      <div>
        <div style={styles.singleCol}>
          <div className='dashboard-section'>
            <CurrentProjectsPanel />
            <PendingProjectsPanel />
            <NewProjectsPanel2 />
          </div>
          <div style={styles.singleCol}>
            <div style={styles.flex}>
              <TaskslistPanel />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
