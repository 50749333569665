import React from 'react';
import {
  Filter,
  SimpleFormIterator,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  NumberInput,
  TextInput,
  useListContext,
  SimpleForm,
  FormDataConsumer,
} from 'react-admin';
import { WrappedSelectArrayValue } from '../../template_components/RemoveDuplicate';


// const ProjectOffersFilters = () => {
//   const listContext = useListContext();
// 	return (
// 		<SimpleForm toolbar={false} name="myFrm">
// 		<FormDataConsumer>
// 		{
// 			({formData}) => {
// 	return (
// 	<Filter variant="standard" >
// 	  <ReferenceArrayInput
// 	    reference="marque"
// 	    source="detail.marque"
// 	    // alwaysOn
// 	  >
// 	    <AutocompleteArrayInput
// 	      label="Marque"
// 	      perpage={1000}
// 	      sort={{ field: 'id', order: 'ASC' }}
// 	      filterToQuery={searchText => ({ id: searchText })}
// 	      options={{ fullWidth: true }}
// 	      allowduplicates={false.toString()}
// 	      fullWidth
// 	      optionText="id"
// 	      optionValue="id"
// 	    />
// 	  </ReferenceArrayInput>
// 	  {listContext.filterValues.detail && listContext.filterValues.detail.marque && (
// 	    <ReferenceArrayInput
// 	      source="detail.modele"
// 	      reference="modele"
// 	      alwaysOn
// 	    >
// 	      <AutocompleteArrayInput
// 		      label="Modèle"
// 	      	      filter={{ marque: listContext.filterValues.detail.marque || '' }}
// 	      	      perpage={1000}
// 	      	      sort={{ field: 'id', order: 'ASC' }}
// 	      	      filterToQuery={searchText => ({ id: searchText })}
// 	              options={{ fullWidth: true }}
// 		      allowduplicates={false.toString()}
// 		      fullWidth
// 		      optionText="id"
// 		      optionValue="id"
// 	      />
// 	    </ReferenceArrayInput>
// 	  )}
// 	  <ArrayInput label="Description" source="detail.options.ilike" alwaysOn>
// 	    <SimpleFormIterator>
// 	      <TextInput label="Mot clé" defaultValue={""} />
// 	    </SimpleFormIterator>
// 	  </ArrayInput>
// 	  <NumberInput label="Année >=" source="detail.annevehicule.gte" />
// 	  <NumberInput label="Année <=" source="detail.annevehicule.lte" />
// 	  <NumberInput label="Kilométrage >=" source="detail.km.gte" />
// 	  <NumberInput label="Kilométrage <=" source="detail.km.lte" />
//     <ReferenceArrayInput
//       source="detail.carburant"
//       reference="fuels"
//       // alwaysOn
//     >
//       <WrappedSelectArrayValue
//         label="Carburant"
// 	name="carburant"
//         sort={{
//           field: 'value',
//           order: 'ASC',
//         }}
//         filterToQuery={searchText => ({ value: searchText })}
//         style={{ minWidth: '100px' }}
//         perpage={1000}
// 	optionText="value"
//         optionValue="value"
//       />
//     </ReferenceArrayInput>
//     <ReferenceArrayInput
//       source="detail.boitedevitesses"
//       reference="gearboxes"
//       // alwaysOn
//     >
//       <WrappedSelectArrayValue
// 	label="Boîte de vitesses"
//         name="boîte de vitesses"
// 	optionText="value"
//         sort={{
//           field: 'value',
//           order: 'ASC',
//         }}
//         filterToQuery={searchText => ({ value: searchText })}
//         style={{ minWidth: '160px' }}
//         perpage={1000}
//         optionValue="value"
//       />
//     </ReferenceArrayInput>
// 	</Filter>
// 	)
// 	}
// 	}
// 	</FormDataConsumer>
// 	</SimpleForm>
// );
// };

// export default ProjectOffersFilters;

const ProjectOffersFilters = () => {
	const listContext = useListContext();
	return (
	  <Filter variant="standard">
		<ReferenceArrayInput
		  reference="marque"
		  source="detail.marque"
		  // alwaysOn
		>
		  <AutocompleteArrayInput
			label="Marque"
			perpage={1000}
			sort={{ field: 'id', order: 'ASC' }}
			filterToQuery={searchText => ({ id: searchText })}
			options={{ fullWidth: true }}
			allowduplicates={false.toString()}
			fullWidth
			optionText="id"
			optionValue="id"
		  />
		</ReferenceArrayInput>
		{listContext.filterValues.detail && listContext.filterValues.detail.marque && (
		  <ReferenceArrayInput
			source="detail.modele"
			reference="modele"
			alwaysOn
		  >
			<AutocompleteArrayInput
			  label="Modèle"
			  filter={{ marque: listContext.filterValues.detail.marque || '' }}
			  perpage={1000}
			  sort={{ field: 'id', order: 'ASC' }}
			  filterToQuery={searchText => ({ id: searchText })}
			  options={{ fullWidth: true }}
			  allowduplicates={false.toString()}
			  fullWidth
			  optionText="id"
			  optionValue="id"
			/>
		  </ReferenceArrayInput>
		)}
		<ArrayInput label="Description" source="detail.options.ilike" alwaysOn>
		  <SimpleFormIterator>
			<TextInput label="Mot clé" defaultValue={""} />
		  </SimpleFormIterator>
		</ArrayInput>
		<NumberInput label="Année >=" source="detail.annevehicule.gte" />
		<NumberInput label="Année <=" source="detail.annevehicule.lte" />
		<NumberInput label="Kilométrage >=" source="detail.km.gte" />
		<NumberInput label="Kilométrage <=" source="detail.km.lte" />
		<ReferenceArrayInput
		  source="detail.carburant"
		  reference="fuels"
		  // alwaysOn
		>
		  <WrappedSelectArrayValue
			label="Carburant"
			name="carburant"
			sort={{
			  field: 'value',
			  order: 'ASC',
			}}
			filterToQuery={searchText => ({ value: searchText })}
			style={{ minWidth: '100px' }}
			perpage={1000}
			optionText="value"
			optionValue="value"
		  />
		</ReferenceArrayInput>
		<ReferenceArrayInput
		  source="detail.boitedevitesses"
		  reference="gearboxes"
		  // alwaysOn
		>
		  <WrappedSelectArrayValue
			label="Boîte de vitesses"
			name="boîte de vitesses"
			optionText="value"
			sort={{
			  field: 'value',
			  order: 'ASC',
			}}
			filterToQuery={searchText => ({ value: searchText })}
			style={{ minWidth: '160px' }}
			perpage={1000}
			optionValue="value"
		  />
		</ReferenceArrayInput>
	  </Filter>
	);
  };
  
  export default ProjectOffersFilters;