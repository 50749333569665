import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BuildIcon from '@mui/icons-material/Build';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import { PG_URL } from '../../../constantes/Utils';
import AutocompleteInput from '../../../template_components/AutocompleteInput';
import {connect} from "react-redux";
import {useRecordContext, useRefresh} from "react-admin";



function SelectRenderer(props) {
  const {
    label,
    changeFunction,
    value,
    array,
    loading,
    className,
  } = props;

  return (
    <div className={className}>
      <DialogContentText>
        {label}
      </DialogContentText>
      {loading ?
        <LinearProgress color="secondary" style={{ width: 190, marginTop: 16 }} />
      :
        <Select onChange={changeFunction} value={value}>
          {array}
        </Select>
      }
    </div>
  );
}

function ObjectToList(obj) {
  let list =[];
  obj.forEach(element => {
    list.push(element.id);
  });
}

SelectRenderer.defaultProps = {
  label: 'SelectRenderer',
  loading: false,
};

SelectRenderer.propTypes = {
  label: PropTypes.string,
  changeFunction: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  loading: PropTypes.bool,
  array: PropTypes.instanceOf(Array).isRequired,
};

function InputRender(props) {
  const { label, changeFunction, className } = props;
  return (
    <div className={className}>
      <DialogContentText>
        {label}
      </DialogContentText>
      <TextField onChange={changeFunction} />
    </div>
  );
}

InputRender.propTypes = {
  label: PropTypes.string,
  changeFunction: PropTypes.func.isRequired,
};

InputRender.defaultProps = {
  label: 'InputRender',
};

const styles = theme => ({
  dialog: {
    maxHeight: 'none',
  },
  dialogContent: {
    minHeight: 350,
  },
	linearProgress: {
		width: 190,
		marginTop: theme.spacing(2),
  },
  input: {
		paddingBottom: theme.spacing(2),
  }
});



const AssignFTButton = ({classes}, rec) => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     opened: false,
  //     loading: false,
  //     cur_datasheets: '',
  //     brand_value: '',
  //     gamme_value: '',
  //     carburant_value: '',
  //     datasheets: [],
  //     gamme_array: [],
  //     original_brand: [],
  //     carburant_array: [],
  //     date: '',
  //     keyword: '',
  //   };
  //   this.handleOpen = this.handleOpen.bind(this);
  //   this.handleClose = this.handleClose.bind(this);
  //   this.handleChangeMarque = this.handleChangeMarque.bind(this);
  //   this.handleChangeGamme = this.handleChangeGamme.bind(this);
  //   this.handleClick = this.handleClick.bind(this);
  //   this.handleChangeCarburant = this.handleChangeCarburant.bind(this);
  //   this.handleChangeDatasheets = this.handleChangeDatasheets.bind(this);
  //   this.handleChangeDate = this.handleChangeDate.bind(this);
  //   this.handleChangeKeyWord = this.handleChangeKeyWord.bind(this);
  // }
  const record = useRecordContext();
  //const record = rec;

  const refresh = useRefresh();
  
  const [state, setState] = useState({
      opened: false,
      loading: false,
      cur_datasheets: '',
      brand_value: '',
      gamme_value: '',
      carburant_value: '',
      datasheets: [],
      gamme_array: [],
      original_brand: [],
      carburant_array: [],
      date: '',
      keyword: '',
  });
  
  useEffect(() => {
    setState((prevState) => ({ ...prevState, loading: true }));

    const urlApi = `${PG_URL}/marque?order=id.asc&select=id`;

    fetch(urlApi)
      .then((response) => response.json())
      .then((data) => {
        const initialBrand = data.map((brand) => brand.id);
        setState((prevState) => ({ ...prevState, original_brand: initialBrand, loading: false, }));
      });
  }, []); 

  // useEffect(() => {
  //   console.log("state.original_brand :: ", state.original_brand);
  // }, [state.original_brand]);

  

  const handleOpen = () => {
    setState((prevState)=> ({...prevState, opened: true, }));
  }

  const handleClose = () => {
    setState((prevState)=> ({...prevState, opened: false, }));
  }

  const handleChangeMarque = (value) => {
    setState((prevState) => ({ ...prevState, loading: true }));

    const urlApi = `${PG_URL}/gamme?marque=eq.${encodeURIComponent(value)}&select=id&order=id`;

      fetch(urlApi)
      .then((response) => response.json())
      .then((data) => {
        const initialGamme = removeDuplicate(data, 'id');
        setState((prevState) => ({
          ...prevState,
          gamme_array: initialGamme,
          loading: false,
        }));
      });
    setState((prevState) => ({
      ...prevState,
      brand_value: value,
    }));
  }

  const handleChangeGamme = (e) => {
    setState((prevState)=> ({...prevState, loading: true, }));

    const url = `${PG_URL}/fiches_techniques?gamme=ilike.${encodeURIComponent(e.target.value)}&marque=eq.${encodeURIComponent(state.brand_value)}&select=carburant&order=carburant`;

    fetch(url).then(response => response.json()).then((data) => {
      const carburantInitial =  removeDuplicate(data, 'carburant');
      setState((prevState) => ({...prevState, carburant_array: carburantInitial, loading: false }));
    });
    setState((prevState) => ({...prevState, gamme_value: e.target.value }));
  }

  const handleChangeCarburant = (e) =>{
    setState((prevState) => ({...prevState, carburant_value: e.target.value }));
  }

  const handleChangeDate = (e) => {
    setState((prevState) => ({...prevState, loading: true }));

    //const { state } = this;
    const urlApi = `${PG_URL}/fiches_techniques?`;

    const url = `${urlApi
    }carburant=ilike.*${encodeURIComponent(state.carburant_value)
    }*&gamme=ilike.${encodeURIComponent(state.gamme_value)
    }&generalites->>date_de_commercialisation=lte.${e.target.value
    }-12-31T23:59:59&or=(generalites->>date_de_fin_de_commercialisation.gte.${e.target.value
    }-01-01T00:00:00,generalites->>date_de_fin_de_commercialisation.is.null)`;

    fetch(url)
      .then(response => response.json()).then((data) => {
        const fichesInitial = data.map(fiche => [fiche.id, fiche.titre]);
        setState((prevState) => ({...prevState, datasheets: fichesInitial, loading: false }));
      });
    setState((prevState) => ({...prevState, date: e.target.value }));
  }

  const handleChangeKeyWord = (e) =>{
    setState((prevState) => ({...prevState, loading: true }));

    const urlApi = `${PG_URL}/fiches_techniques?`;
    //const { state } = this;

    const url = `${urlApi}carburant=ilike.*${encodeURIComponent(state.carburant_value)
    }*&gamme=ilike.${encodeURIComponent(state.gamme_value)
    }&generalites->>date_de_commercialisation=lte.${state.date
    }-12-31T23:59:59&or(generalites->>date_de_fin_de_commercialisation.gte.${state.date
    }-01-01T00:00:00,generalites->>date_de_fin_de_commercialisation.is.null)&titre=ilike.*${encodeURIComponent(e.target.value)}*`;

    fetch(url)
      .then(response => response.json()).then((data) => {
        const fichesInitial = data.map(fiche => [fiche.id, fiche.titre]);
        setState((prevState) => ({...prevState, datasheets: fichesInitial, loading: false }));
      });
    setState((prevState) => ({...prevState, keyword: e.target.value }));
  }

  const handleChangeDatasheets = (e) => {
    setState((prevState) => ({...prevState, cur_datasheets: e.target.value }));
  }

  const handleClick = (e) =>{
    const ids_reconciliation = record.id;
    const id_fichetechnique = state.cur_datasheets[0];
    const url_api = `${PG_URL}/correspondances`;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Prefer', 'resolution=merge-duplicates');
    fetch(url_api, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        id_theorique: ids_reconciliation,
        table: 'fiches_techniques',
        id_annonce: id_fichetechnique,
      }),
    }).then(() => {
      setState((prevState) => ({ ...prevState,  opened: false}));
      refresh();
    });
    // .finally(
    //     refreshView()
    // );
  };

	const removeDuplicate = (array, field) => {
		let values = []
		array.forEach((elem) => {
			if (elem[field] && !values.includes(elem[field].toLowerCase())) {
				values.push(elem[field].toLowerCase())
			}
		})
		return values
	};

    const {
      loading,
      opened,
      datasheets,
      brand_value: brandValue,
      gamme_value: gammeValue,
      carburant_value: carburantValue,
      date,
      cur_datasheets: curDatasheets,
      original_brand: originalBrand,
      gamme_array: gammeArray,
      carburant_array: carburantArray,
    } = state;
    
    // console.log("gamme :: ", loading, originalBrand, brandValue);
    
    const gammeSelect = gammeArray ? gammeArray.map(title => <MenuItem value={title}>{title}</MenuItem>) : null;
    const carburantSelect = carburantArray ? carburantArray.map(carburant => (
      <MenuItem
        value={carburant}
      >
        {carburant}
      </MenuItem>
    )) : null;
    const datasheetsSelect = datasheets ? datasheets.map(datasheet => (
      <MenuItem
        value={datasheet}
      >
        {datasheet[1]}
      </MenuItem>
    )) : null;
    
    
   return (
      <div>
        <Dialog open={state.opened} onClose={() =>handleClose()} aria-labelledby="form-dialog-title" PaperProps={{ className: classes.dialog }}>
          <DialogTitle id="form-dialog-title">
            Ajouter une
            <strong> fiche technique</strong>
            {' '}
            à cette annonce :
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.input}>
              <DialogContentText>
                Veuillez sélectionner une marque:
              </DialogContentText>
               {originalBrand ? (
                <AutocompleteInput
                choices={originalBrand}
                value={brandValue}
                onChange={handleChangeMarque}
                placeholder={"Marque"}
                fullWidth
              />)
              :
                (<LinearProgress color="secondary" className={classes.linearProgress} />)
              } 
            </div>
            {brandValue &&
              <SelectRenderer
                className={classes.input}
                changeFunction={handleChangeGamme}
                value={gammeValue}
                array={gammeSelect}
                label="Veuillez sélectionner une gamme:"
                loading={!gammeValue && loading}
              />
            }
            {gammeValue &&
              <SelectRenderer
                className={classes.input}
                changeFunction={handleChangeCarburant}
                value={carburantValue}
                array={carburantSelect}
                label="Veuillez sélectionner le carburant:"
                loading={!carburantValue && loading}
              />
            }
            {carburantValue &&
              <InputRender className={classes.input} changeFunction={handleChangeDate} label="Entrez l'année souhaitez:" />
            }
            {date &&
              <>
                <InputRender
                  className={classes.input}
                  changeFunction={handleChangeKeyWord}
                  label="(Optionel) Entrez un mot clé:"
                />
                <SelectRenderer
                  className={classes.input}
                  changeFunction={handleChangeDatasheets}
                  value={curDatasheets}
                  array={datasheetsSelect}
                  label="Pour finir sélectionner votre fiche technique:"
                  loading={loading}
                />
              </>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} /*color="secondary" */>
              Fermer
            </Button>
            {curDatasheets &&
              <Button onClick={handleClick} /*color="secondary" */>
                Valider
              </Button>
            }
          </DialogActions>
        </Dialog>
        <Button variant="outlined" /*color="secondary"*/ onClick={handleOpen}>
          <BuildIcon />
          &nbsp;
          Assigner une fiche technique
        </Button>
      </div>
    );
  
}



export default connect(undefined, {refresh: useRefresh})(
    AssignFTButton
);
