import React, { Component, useState } from 'react';
import { Button } from '@mui/material';
import ExportIcon from '@mui/icons-material/SaveAlt';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import style from '@mui/system/style';
import { PG_URL } from '../../../../constantes/Utils';
import { getTinderformMail } from '../../../../constantes/mail_templates';
import OutlinedButton from '../../../../template_components/OutlinedButton';
import FeedbackSnackbar from '../../../../template_components/FeedbackSnackbar';
import { Select, MenuItem, InputLabel } from '@mui/material';

import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';

// import { DateFnsUtils } from '@date-io/date-fns';
import format from 'date-fns/format';


const styles = {
  buttonProgress: {
    "position": 'absolute',
    "top": '50%',
    "left": '50%',
    "marginTop": "-12",
    "marginLeft": "-12",
  },
};

const BulkCreateTypeformButton = () => {

  const [state, setState] = useState({
    loading: false,
    lien: null,
    open: false,
    error: false,
    message: '',
    duration: 3000,
    variant: '',
    planify: false,
    date_plan: setPlannerDate(),
  });
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     loading: false,
  //     lien: null,
  //     open: false,
  //     error: false,
  //     message: '',
  //     duration: 3000,
  //     variant: '',
  //     planify: false,
  //     date_plan: this.setPlannerDate(),
  //   };
  //   this.handleClick = this.handleClick.bind(this);
  //   this.copyToClipboard = this.copyToClipboard.bind(this);
  //   this.sendToCustomer = this.sendToCustomer.bind(this);
  //   this.changeOperator = this.changeOperator.bind(this);
  //   this.changeDate = this.changeDate.bind(this);
  // }

  const convertUTCDateToLocalDate = (date) => {

    let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    let offset = date.getTimezoneOffset() / 60;
    let hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  const setPlannerDate = () => {
    let smartDate = new Date();

    if (new Date().getHours() < 12 || new Date().getHours() >= 18) {
      smartDate.setHours(12);
      smartDate.setMinutes(15);
    }
    else {
      smartDate.setHours(17);
      smartDate.setMinutes(45);
    }

    return (smartDate);
  }

  const handleClick = param => (e) => {
    setState((prevState) => ({ ...prevState, loading: true, error: false }));

    const req = {
      ids: '{' + props.selectedIds.toString() + '}',
      typeform_version: param
    };
    const urlApi = `${PG_URL}/rpc/phase_two_typeform_manager`;  //
    const body = JSON.stringify(req);

    fetch(urlApi, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: body })
      .then(response => {
        if (!response.ok) throw new Error("Impossible de récupérer les données des recherches");
        return response.json();

      })
      .then(async (researches) => {
        var regex = /'/gi;
        researches = researches.replace(regex, '"');
        const typeform_resp = JSON.parse(researches);
        const link = typeform_resp.typeform ? typeform_resp.typeform : null;
        setState((prevState) => ({ ...prevState, loading: false, open: true, lien: link, user: typeform_resp }));
      })
      .catch((e) => {
        console.error(e);
        setState((prevState) => ({ ...prevState, loading: false, error: true }));
      });

    const urlOpe = `${PG_URL}/operators?select=id,firstname,lastname,email,phone,role&is_active=eq.true`;

    fetch(urlOpe, { method: 'GET' })
      .then(response => {
        if (!response.ok) throw new Error("Impossible de récupérer la liste des opérateurs");
        return response.json();
      })
      .then(async (operateurs) => {
        setState((prevState) => ({ ...prevState, operators: operateurs, op: operateurs[1], selected_op: 1 }));
      })
      .catch((e) => {
        console.log(e);
        setState((prevState) => ({ ...prevState, loading: false, error: true, variant: 'error' }));
      });
  }

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, open: false }));
  }

  const sendToCustomer = () => {
    //const { state, props } = this;
    const { user, lien, op } = state;
    const { selectedIds } = props;

    this.setState({ loading: true, variant: 'info', message: 'Envoi en cours...', duration: null });
    user["select_researches"] = selectedIds.length;
    user.lien = lien;
    user["tracked_id"] = Date.now();

    const receiver_emails = [user.mail];

    const mail = {
      receiver_emails,
      receiver_name: user.name,
      operator: {
        id: op.id,
        email: op.email,
        password: null
      },
      subject: "🔔 Car-Expresso : À vous de choisir !",
      message: getTinderformMail(user, op), // html
      files: [],
    };
    const mail_data = {
      "mail_data": mail,
      "project_id": user.project_id,
      "typeform_id": user.typeform_id,
    };

    const urlMail = `${PG_URL}/rpc/phase_two_sendmail`;  //

    if (mail_data.typeform_id === '')
      throw new Error("Impossible de récupérer le typeform_ID");
    else {
      fetch(urlMail, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(mail_data) })
        .then(response => {
          return response.json();
        })
        .then(response => {
          var regex = /'/gi;
          response = response.replace(regex, '"');
          const resp = JSON.parse(response);
          if (resp.status.includes("500")) {
            setState((prevState) => ({ ...prevState, loading: false, error: true, variant: 'error', message: 'Erreur dans l\'envoi du mail', duration: 2000 }));
            throw new Error("Erreur dans l'envoi du mail");
          }
          console.log(response);
          setState((prevState) => ({ ...prevState, loading: false, variant: 'success', message: 'Mail envoyé ! Le projet passe en phase 2', duration: 2000 }));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  const planifySendModal = () => {
    setState((prevState) => ({ ...prevState, planify: true }));
  }

  const planifySend = () => {
    //const { state, props } = this;
    const { user, lien, op, date_plan } = state;
    const { selectedIds } = props;

    setState((prevState) => ({ ...prevState, loading: true, variant: 'info', message: 'Envoi en cours...', duration: null }));
    user["select_researches"] = selectedIds.length;
    user.lien = lien;
    user["tracked_id"] = Date.now();

    const receiver_emails = [user.mail];

    const mail = {
      receiver_emails,
      receiver_name: user.name,
      operator: {
        id: op.id,
        email: op.email,
        password: null
      },
      subject: "🔔 Car-Expresso : À vous de choisir !",
      message: getTinderformMail(user, op), // html
      files: [],
    };

    const mail_data = {
      "mail_data": mail,
      "project_id": user.project_id,
      "plannified_date": format(date_plan, 'yyyy-MM-dd HH:mm:ss', 'Europe/Paris'), // date_plan.toString().slice(0, 24)
      "regardingobjecttypecode": "typeform",
      "regardingobjectid": user.typeform_id,
    };

    const urlMail = `${PG_URL}/mail_planner`;
    fetch(urlMail, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(mail_data) })
      .then(response => {
        if (!response.ok) {
          setState((prevState) => ({ ...prevState, loading: false, error: true, variant: 'error', message: 'Erreur dans la planification', duration: 2000 }));
          throw new Error("Erreur dans l'envoi du mail");
        }
        else
          setState((prevState) => ({ ...prevState, loading: false, variant: 'success', message: 'Mail planifié ! Le projet va passer en phase 2', duration: 2000 }));
      });
  }

  const copyToClipboard = (e) => {
    textArea.select();
    document.execCommand('copy');
    e.target.focus();
    setState((prevState) => ({ ...prevState, open: false }));
  }

  const changeOperator = (e) => {
    //const { state } = this;
    const { operators } = state;
    const i = e.target.value;

    setState((prevState) => ({ ...prevState, op: operators[i], selected_op: i }));
  }

  const changeDate = (e) => {
    setState((prevState) => ({ ...prevState, date_plan: e }));
  }

  //const { state, props } = this;
  const { classes } = props;
  //const { open, loading, lien, error, message, duration, variant, operators, selected_op, planify, date_plan } = state;
  // const opOptions = operators.map((op) => <MenuItem key={op.username} value={op.username}>{op.firstname} {op.lastname}</MenuItem>)

  let dialog = null;
  if (!lien) {
    dialog = <Dialog open={state.open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Impossible de créer le typeform</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Certaines recherches ne contiennent pas d'images ! Merci d'upload les images puis recommencez.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="secondary" variant="contained">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  } else {
    dialog = <Dialog open={state.open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Lien du formulaire</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Lien :
        </DialogContentText>
        <textarea
          ref={(textarea) => { textArea = textarea; }}
          value={state.lien}
          readOnly
          rows={3}
          style={{ minWidth: '300px' }}
        />
        <br />
        <InputLabel id="operator-select-label-typeform-two" style={{ margin: '20px 20px 0px 0px' }}>Opérateur</InputLabel>
        <Select
          style={{ minWidth: '200px', margin: '20px 0px 0px 0px' }}
          value={selected_op}
          onChange={() => changeOperator()}>
          {operators.map((ope, index) =>
            <MenuItem value={index} key={index}>{ope.firstname} {ope.lastname}</MenuItem>
          )}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => copyToClipboard()} color="secondary" variant="contained">
          Copier et fermer
        </Button>
        <Button onClick={() => sendToCustomer()} color="secondary" variant="contained" disabled={state.loading}>
          {state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          Envoyer par mail
        </Button>
        <Button onClick={() => planifySendModal()} color="secondary" variant="contained" disabled={state.loading}>
          {state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          Planifier
        </Button>
      </DialogActions>
      {state.message === '' ? null : <FeedbackSnackbar duration={state.duration} variant={state.variant} message={state.message} style={{ display: 'absolute', float: 'top' }} />}
    </Dialog>
  }
  if (planify) {
    dialog = <Dialog open={state.open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
      <DialogContent>
        {/* <LocalizationProvider utils={DateFnsUtils}> */}
        <LocalizationProvider>
          <DateTimePicker
            autoOk={true}
            hintText="Date et heure de planification"
            value={state.date_plan}
            onChange={() => changeDate()}
          />
        </LocalizationProvider>
        <Button onClick={() => planifySend()} color="secondary" variant="contained">
          Planifier
        </Button>
      </DialogContent>
      {state.message === '' ? null : <FeedbackSnackbar duration={state.duration} variant={state.variant} message={state.message} style={{ display: 'absolute', float: 'top' }} />}
    </Dialog>
  }


  return (
    <>
      <OutlinedButton
        icon={<ExportIcon />}
        loading={state.loading}
        error={state.error}
        onClick={handleClick(2)}
        label={'Créer le typeform CB'}
        loadingLabel={'Création du typeform...'}
      />
      <OutlinedButton
        icon={<ExportIcon />}
        loading={state.loading}
        error={state.error}
        onClick={handleClick(1)}
        label={'Créer le typeform'}
        loadingLabel={'Création du typeform...'}
      />
      {dialog}
    </>
  );

}

export default BulkCreateTypeformButton;

