import React from 'react';
import {
	Show,
	List,
	Pagination,
	Datagrid,
	TextField,
	NumberField,
	useRecordContext
} from 'react-admin';

import SimpleArrayField from '../../template_components/SimpleArrayField';
import PriceField from '../../template_components/PriceField';
import DistanceField from '../../template_components/DistanceField';

import ResearchRowButtons from '../../needs/researches/ResearchRowButtons';
import ResearchBulkActionButtons from '../../needs/researches/ResearchBulkActionButtons';
//import NeedShowActions from './ProjectNeedsActions';
import ProjectNeedsActions from './ProjectNeedsActions';

const ProjectNeedsShow = (rec) => {
	console.log("props : ", rec);
	const record = useRecordContext();
	console.log("useRecordContext : ", record);
	return (
		<Show {...rec}
			title=" "
		>
			<NeedResearchList {...rec}
				project_id={record.project_id}
				label=" "
				target="need_id"
				reference={"needs_search_data"}
				sort={{
					field: 'marque',
					order: 'ASC',
				}}
				// filter={{
				// 	need_id: record.id,
				// }}
				resource={"needs_search_data"}
			//basePath = {"need_search_data"}
			/>
		</Show>
	);
}


const NeedResearchList = (record) => {
	//const record = useRecordContext();
	// console.log(record.project_id ? "/vprojects_reconciliations/"+record.project_id+"/show/4" : "/needs");
	// console.log("project_id", record.project_id ? record.project_id : undefined);
	// console.log("need_id", record.id);
	return (
		<List
			{...record}
			resource={"needs_search_data"}
			reference="needs_search_data"
			//basePath = {"need_search_data"}
			title={" "}
			exporter={false}
			// actions={<ProjectNeedsActions {...record}/>} // inutile car recopie juste le bouton du haut
			//bulkActionButtons={<ResearchBulkActionButtons project_id={props.record.project_id} />}
			filter={{
				need_id: parseInt(record.id)
			}}
			sort={{
				field: 'marque',
				order: 'ASC',
			}}
			perPage={50}
			pagination={<Pagination rowsPerPageOptions={[25, 50, 75, 100]} />}
			location={{
				pathname: record.project_id ? "/vprojects_reconciliations/" + record.project_id + "/show/4" : "/needs",
				search: "",
				hash: "",
				state: undefined
			}}
			hasEdit={false}
			// hascreate={false}
			hasShow={false}
			hasList={false}

		>
			<Datagrid
				bulkActionButtons={<ResearchBulkActionButtons project_id={record.project_id} />}
			>
				{/* <UploadImageField {...props} />  */}
				<PriceField label="Prix minimum" source="prixmin" />
				<PriceField label="Prix maximum" source="prixmax" />
				<TextField label="Marque" source="marque" />
				<TextField label="Gamme" source="modele" />
				<SimpleArrayField label="Boîte de vitesses" source="boite" />
				<SimpleArrayField label="Carburant" source="carburant" />
				<SimpleArrayField label="Flags" source="segments" />
				<SimpleArrayField label="Mots clés" source="keywords" />
				<NumberField label="Année circulation" source="annee" />
				<DistanceField label="Kilométrage maximum" source="max_km" />
				<NumberField label="Age moyen" source="avg_year" />
				<DistanceField label="Km moyen" source="avg_km" />
				<NumberField label="Disponibilité" source="count" />
				<ResearchRowButtons
					redirect={record.project_id ? `/vprojects_reconciliations/${record.project_id}/show/4` : `/needs`}
					{...record}
				/>
			</Datagrid>
		</List>
	);
}

export default ProjectNeedsShow;
