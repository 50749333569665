import React, {Component} from 'react';
import Button from '@mui/material/Button';

import DeleteIcon from '@mui/icons-material/Delete';

import {PG_URL} from "../constantes/Utils";

import { useRecordContext} from "react-admin";
import {connect} from 'react-redux';

const CustomDeleteNeedsSearchButtonButton = (props) => {
    console.log(props);
    /*
       Custom delete button because react admin delete button props basePath cannot be override.
       This can be used as template for all custom button.
     */
    // constructor(props) {
    //     super(props);
    //     this.onClick = this.onClick.bind(this);
    // }
    const record = useRecordContext();
    const onClick = () => {
        const urlApi = `${PG_URL}/needs_search?id=eq.` + record.id;

        const customHeaders = new Headers();
        customHeaders.append('Content-Type', 'application/json');

        fetch(urlApi, {
            method: 'DELETE',
            headers: customHeaders,
        })
            .then((response) => {
                if (!response.ok) throw new Error(`Impossible de supprimer le besoin !`);
                refresh();
            })
            .catch((e) => {
                console.log(e);
            });

    }

        // const {props} = this;
        // const {
        //     name: nameVal,
        //     color: colorVal,
        // } = props;

        const styles = {
            root: {
                float: 'right',
                position: 'relative',
                color: '#f44336',
            }
        };

        return (

            <Button 
                style={styles.root} onClick={onClick} 
                //color={colorVal !== undefined ? colorVal : 'red'}
                >
                <DeleteIcon color="error"/>
                &nbsp;
                {/* {nameVal} */}
            </Button>

        );
    
}

export default CustomDeleteNeedsSearchButtonButton;
