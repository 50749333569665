import React from 'react';
import { useLocation } from 'react-router-dom';
// import { parse } from 'query-string';
import parse from 'date-fns/parse';
import { Create, TopToolbar, SimpleForm, TextInput, BooleanInput} from 'react-admin';
import Button from '@mui/material/Button';
import OperatorTitle from './OperatorTitle';


const OperatorCreate = () => {
	return (
	<Create title={<OperatorTitle create />} >
		<SimpleForm>
			<TextInput label="Nom" source="lastname" />
			<TextInput label="Prénom" source="firstname" />
			<TextInput label="Username" source='username' />
			<TextInput label="Email" source="email" />
			<TextInput label="Téléphone" source="phone" />
			<BooleanInput
				label="Administrateur"
				source="role"
				defaultValue={false}
				parse={value => value ? 'admin' : 'staff'}
				format={value => value === 'admin'}
			/>
		</SimpleForm>
	</Create>
)};

export default OperatorCreate;
