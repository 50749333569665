import React, { useEffect, useCallback } from 'react';
import { useRecordContext } from 'react-admin';
import Button from '@mui/material/Button';
import { SCRAPYD_URL } from '../constantes/Utils';
import LinkIcon from '@mui/icons-material/Link';
import LinearProgress from '@mui/material/LinearProgress';

const LogLinkButton = () => {
  const record = useRecordContext();
  let newLink = '';

  useEffect(() => {
    let sub_link = '';
    let sub_sub_link = '';

    if (record?.source === 'offers_of') {
      sub_link = 'scraper_ouestfrance';
      sub_sub_link = 'ouestfrance';
    }

    if (record?.source === 'offers_lc') {
      sub_link = 'scraper_lacentrale';
      sub_sub_link = 'lacentrale';
    }

    if (record?.source === 'offers_lbc') {
      sub_link = 'scraper_leboncoin';
      sub_sub_link = 'leboncoin';
    }

    newLink = `${SCRAPYD_URL}/logs/${sub_link}/${sub_sub_link}/${record?.id}.log`;
  }, [record]);

  const onClick = useCallback(() => {
    window.open(newLink);
  }, [newLink]);

  const styles = {
    float: 'right',
    position: 'relative',
  };

  return (
    <div>
      {record ? (
        <Button style={styles} onClick={onClick} variant="outlined" color="secondary">
          <LinkIcon />
          &nbsp;Go to logs
        </Button>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
};

export default LogLinkButton;
