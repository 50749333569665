import React, { useState, useEffect } from 'react';
import {
	Show,
	TextField,
	SimpleShowLayout,
	FunctionField,
	TopToolbar,
	EditButton
} from 'react-admin';
import GppGoodIcon from '@mui/icons-material/GppGood';
import CancelIcon from '@mui/icons-material/Cancel';
import { useLocation, useParams } from 'react-router-dom';
import { PG_URL } from '../constantes/Utils';
import { green, red } from '@mui/material/colors';
import { useRecordContext } from 'ra-core/dist/cjs';
import OnlineDisplay from './components/OnlineDisplay';
import Button from '@mui/material/Button';


const OperatorShow = (props) => {
	// const id = useLocation().pathname.split("/")[1];
	// const [state, setState] = useState({
	// 	timestamps: []
	// });
	// useEffect(() => {
	// 	fetch(`${PG_URL}/active_jwt?select=issued_datetime&user_id=eq.${id}`)
	// }, []);
	const OperatorsActions = () => (
		<TopToolbar>
			<Button
				color="secondary"
				variant="outlined"
				href={`/operators`}
			>
				Retour
			</Button>
			<EditButton/>
		</TopToolbar>
	);
	return (
		<Show {...props}
			title=" "
			actions={<OperatorsActions/>}
		>
			<SimpleShowLayout>
				<FunctionField
					label="NOM / Prénom"
					render={record => `${record.lastname.toUpperCase()} ${record.firstname}`}
				/>
				<TextField source='username' label="Username" />
				<TextField source='email' label='Email' />
				<TextField source='phone' label='Téléphone' />
				<FunctionField
					label="Admin"
					render={record => record.role === "admin" ? <GppGoodIcon sx={{ color: green[500] }} /> : <CancelIcon sx={{ color: red[500] }} />}
				/>
				<OnlineDisplay />
			</SimpleShowLayout>
		</Show>
	);
}

export default OperatorShow;
