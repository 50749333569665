import React, {Component} from 'react';
import Button from '@mui/material/Button';

import DeleteIcon from '@mui/icons-material/Delete';

import {PG_URL} from "../constantes/Utils";

import {connect} from 'react-redux';

class CustomBulkDeleteNeedsSearchButtonButton extends Component {
    /*
       Custom delete button because react admin delete button props basePath cannot be override.
       This can be used as template for all custom button.
     */
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {

        const urlApi = `${PG_URL}/needs_search?id=in.(${this.props.selectedIds.toString()})`;

        const customHeaders = new Headers();

        customHeaders.append('Content-Type', 'application/json');

        fetch(urlApi, {
            method: 'DELETE',
            headers: customHeaders,
        })
            .then((response) => {
                if (!response.ok) throw new Error(`Impossible de supprimer le(s) besoin !`);
            })
            .catch((e) => {
                console.log(e);
            })

    }

    render() {

        const {props} = this;
        const {
            name: nameVal,
            color: colorVal,
        } = props;

        const styles = {
            root: {
                float: 'right',
                position: 'relative',
                color: 'primary',
            }
        };

        return (

            <Button style={styles.root} onClick={this.onClick} color="primary">
                <DeleteIcon color="error"/>
                &nbsp;
                {nameVal}
            </Button>

        );
    }
}

// export default connect(undefined, {refreshView: () => {refresh()}})(
//     CustomBulkDeleteNeedsSearchButtonButton
// );
export default CustomBulkDeleteNeedsSearchButtonButton;