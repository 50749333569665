import React, { useState } from 'react';
import {
	Edit,
	SimpleForm,
	TextInput,
	BooleanInput,
	useRecordContext,
	TopToolbar,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';

import OperatorTitle from './OperatorTitle';

const OperatorEdit = (props) => {
	const record = useRecordContext();
	const params = useParams();

    // Vérifie si record est défini, sinon, utilise un objet vide par défaut
    const roleValue = record ? record.role : '';

	const OperatorsActions = () => (
		<TopToolbar>
			<Button
				color="secondary"
				variant="outlined"
				href={`/operators/${params.id}/show`}
			>
				Retour
			</Button>
		</TopToolbar>
	);
	
    return (
        <Edit title={<OperatorTitle edit />} {...props} actions={<OperatorsActions/>}>
            <SimpleForm>
                <TextInput label="Nom" source="lastname" />
                <TextInput label="Prénom" source="firstname" />
				<TextInput label="Username" source="username" />
                <TextInput label="Email" source="email" />
                <TextInput label="Téléphone" source="phone" />
				<BooleanInput
					label="Administrateur"
					source="role"
					parse={value => value ? 'admin' : 'staff'} // Convertit la valeur booléenne en 'admin' ou 'staff'
					format={value => value === 'admin'} // Convertit la valeur 'admin' ou 'staff' en booléen
				/>
            </SimpleForm>
        </Edit>
    );
};

export default OperatorEdit;