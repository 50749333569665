import React, { Component } from 'react';
import {redirect} from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedButton from '../../../../template_components/OutlinedButton';
import { PG_URL } from '../../../../constantes/Utils';

class BulkSearchButton extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      loading: false, 
      redirect: false,
      error: false,
      target: '', 
      data: [],
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ loading: true, error: false });

    const req = this.props.selectedIds.join(',id.eq.')
    const urlApi = `${PG_URL}/needs_search?or=(id.eq.${req})`;

    fetch(urlApi)
      .then(response => {
        if (!response.ok) throw new Error("Impossible de récupérer les données des recherches !");
        return response.json();
      })
      .then((data) => {
        let brands = [], models = [], years = [], price_min = null, price_max = null, max_km = null, gearboxes = [], fuels = [], keywords = [];
        data.forEach(record => {
          if (record.marque && record.marque !== null && !brands.includes('%22'+record.marque+'%22')) brands.push('%22'+record.marque+'%22')
          if (record.modele && record.modele !== null && !models.includes('%22'+record.modele+'%22')) models.push('%22'+record.modele+'%22')
          years.push(record.annee)
          if (record.keywords) {
            try {
              record.keywords.forEach(keyword => {
                if (!keywords.includes('%22'+keyword+'%22')) {
                  keywords.push('%22'+keyword+'%22')
                }
              })
            } catch (e) {
              if (!fuels.includes('%22'+record.carburant+'%22')) {
                fuels.push('%22'+record.carburant+'%22')
              }
            }
          }
          if (record.carburant) {
            try {
              record.carburant.forEach(fuel => {
                if (!fuels.includes('%22'+fuel+'%22')) {
                  fuels.push('%22'+fuel+'%22')
                }
              })
            } catch (e) {
              if (!fuels.includes('%22'+record.carburant+'%22')) {
                fuels.push('%22'+record.carburant+'%22')
              }
            }
          }
          if (record.boite) {
            try {
              record.boite.forEach(gearbox => {
                if (!gearboxes.includes('%22'+gearbox+'%22')) {
                  gearboxes.push('%22'+gearbox+'%22')
                }
              })
            } catch (e) {
              if (!gearboxes.includes('%22'+record.boite+'%22')) {
                gearboxes.push('%22'+record.boite+'%22')
              }
            }
          }
          if ((record.prixmin && record.prixmin !== null) && (price_min === null || record.prixmin < price_min)) {
            price_min = record.prixmin
          }
          if (record.prixmax > price_max) {
            price_max = record.prixmax
          }
          if (record.max_km > max_km) {
            max_km = record.max_km
          }
        })
        brands.join(',')
        models.join(',')
        fuels.join(',')
        gearboxes.join(',')
        keywords.join(',')
        const year_min = Math.min(...years)
        const year_max = Math.max(...years)

        let url = `/offers_all?filter={"existe":true,"json_propre":{"sure":{`
          + ((Array.isArray(models) && models.length) ? `"modele":[${models}],` : '')
          + (year_max === 0 ? '' : `"annee_modele":{"gte":${year_min},"lte":${year_max}},`)
          + (price_min === null || price_max === null ? '' : `"prix":{"gte":${price_min},"lte":${price_max}},`)
          + (max_km === null ? '' : `"kilometrage":{"lte":${max_km}},`)
          + ((Array.isArray(gearboxes) && gearboxes.length) ? `"boite_de_vitesses":[${gearboxes}],` : '')
          + ((Array.isArray(fuels) && fuels.length) ? `"carburant":[${fuels}],` : '')
          + ((Array.isArray(keywords) && keywords.length) ? `"description":{"ilike":[${keywords}]},` : '')
          + `"marque":[${brands}]`
          + `}}}&order=ASC&page=1&perPage=25&sort=json_propre['sure']['date_publication']`
          
        this.setState({target: url, loading: false, redirect: true})
      })
      .catch((e) => {
        console.log(e)
        this.setState({ loading: false, error: true });
    });
  }

	renderRedirect = () => {
		if (this.state.redirect) {
			//return <Redirect push={true} to={this.state.target} />
      redirect(this.state.target)
		}
	}

  render() {
    const { loading, error } = this.state;

    return (
      <>
        {this.renderRedirect()}
        <OutlinedButton
          icon={<SearchIcon />}
          loading={loading}
          error={error}
          onClick={this.handleClick}
          label={'Rechercher'}
          loadingLabel={'Création de la recherche...'}
        />
      </>
    );
  }
}

export default BulkSearchButton;