import React from 'react';
import {
  Tab,
  ReferenceManyField,
  TextField,
  Datagrid,
  FunctionField,
  DateField,
  TabbedShowLayout,
  useRecordContext,
} from 'react-admin';
import BugReportIcon from '@mui/icons-material/BugReport';
import ProjectScrapersActions from './ProjectScrapersActions';
import ProjectScrapersShow from './ProjectScrapersShow';
import LogLinkButton from "../../template_components/LogLinkButton";

const ProjectScrapersTab = () => (
  <TabbedShowLayout.Tab label="scrapers">
    <ProjectScrapersActions />
    <ReferenceManyField
      addLabel={false}
      target="project_id"
      reference="projects_scrapers"
      sort={{
        field: 'row_inserted',
        order: 'DESC',
      }}
      fullWidth
    >
      <Datagrid expand={<ProjectScrapersShow />} rowClick="expand" >
        <TextField label="Id" source="id" />
        <TextField label="Status" source="status" />
        <TextField label="Source" source="source" />
        <FunctionField
          label="Nombre de recherches"
          render={record => ((Array.isArray(record.researches) && record.researches) ? record.researches.length : null)}
        />
        <DateField label="Date de lancement" source="row_inserted" showTime />
        <DateField label="Date de fin" source="finish_time" showTime />
        <LogLinkButton />
      </Datagrid>
    </ReferenceManyField>
  </TabbedShowLayout.Tab>
)

export default ProjectScrapersTab;
