import React from 'react';
import {
    Tab,
    ReferenceManyField,
    TextField,
    Datagrid,
    DateField,
    TabbedShowLayout,
    useListContext,
    useRecordContext
} from 'react-admin';
import FavoriteIcon from '@mui/icons-material/Favorite';

const ProjectBestOffersTab = () => {

    return (
    <TabbedShowLayout.Tab icon={<FavoriteIcon />} label="Recommandations" resource='rpc/ce_best_offers'>
        <ReferenceManyField 
            label=""
            addLabel={false} 
            reference="rpc/ce_best_offers"
            target="id"
            filter={{ 
		    'boite_de_vitesses@': '',
		    'carburant@': '',
		    'modele@': '',
		    'marque@': '',
		    'minbudget@':null,
		    'maxbudget@':null,
		    'zipcode@':'',
		    'journeytime@':'',
		    'updateddate@':'',
		    'keyword@': '',
	    }} 
            sort={{ field: 'publication', order: 'DESC' }} 
        >
            <Datagrid>
                <DateField source="publication" label="Publication" />
                <TextField source="url" label="Url" />
            </Datagrid>
        </ReferenceManyField>
    </TabbedShowLayout.Tab>
)
}

export default ProjectBestOffersTab;
