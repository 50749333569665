import React from 'react';
import { TopToolbar } from 'react-admin';
import AllPublipostageButton from './components/AllPublipostageButton';
import AllSendToCustomerSpaceButton from './components/AllSendToCustomerSpaceButton';
import FetchCustomerChoicesButton from './components/FetchCustomerChoicesButton';

const ProjectOffersListActions = props => (
  <TopToolbar>
    {/* <FetchCustomerChoicesButton record={props.record} /> */}
    {/* <AllSendToCustomerSpaceButton record={props.record} /> */}
    {/* <AllPublipostageButton record={props.record} /> */}
  </TopToolbar>
);

export default ProjectOffersListActions;
