import React, { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import style from "@mui/system/style";
import { PG_URL, CORS_ANYWHERE } from "../constantes/Utils";
import { useRecordContext } from "ra-core";

const useStyles = {
  root: {
    "color": "rgba(0, 0, 0, 0.54)",
    "fontSize": "0.9285714285714286rem",
    "fontWeight": 400,
    "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
    "lineHeight": "1.5",
    "letterSpacing": "0.00938em",
  },
};

const Carousel = (props) => {
  const [state, setState] = useState({
    imgIndex: 0,
    est_introuvable: false,
  });
  const record = useRecordContext();


  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     imgIndex: 0,
  //   };
  //   this.handleClick = this.handleClick.bind(this);
  // }
  // const componentDidMount = () => {
  //   const { props } = this;
  //   const { record, source } = props;
  //   const images = get(record, source);

  //   try {
  //     if (images && CORS_ANYWHERE) {
  //       const response = await fetch(
  //         CORS_ANYWHERE + "/" + images[0].replace("https://", ""),
  //         { method: "HEAD" }
  //       );

  //       if (response.status === 404) {
  //         var urlApi = `${PG_URL}/rpc/ce_offre_desactivation`;

  //         const id = {
  //           id: record.id,
  //         };

  //         const body = JSON.stringify(id);

  //         const res = await fetch(urlApi, {
  //           method: "POST",
  //           headers: { "Content-Type": "application/json" },
  //           body: body,
  //         });

  //         this.setState({
  //           est_introuvable: true,
  //         });
  //       } else {
  //         this.setState({
  //           est_introuvable: false,
  //         });
  //       }
  //     } else {
  //       this.setState({
  //         est_introuvable: true,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const handleClick = (e) => {
    e.stopPropagation();
    //const { state, props } = this;

    const images = get(record, props.source);
    const oldIndex = state.imgIndex;

    setState((prevState) => ({
      ...prevState,
      imgIndex: oldIndex + 1,
    }));
    if (state.imgIndex >= images.length - 1) {
      setState((prevState) => ({
        ...prevState,
        imgIndex: 0,
      }));
    }
  }

  // const componentDidUpdate = (prevProps) => {
  //   if (this.props.record !== prevProps.record) {
  //     this.setState({
  //       imgIndex: 0,
  //     });
  //   }
  // }


  //const { state, props } = this;
  const images = get(record, props.source);
  const { imgIndex } = state;

  const style = {
    width: props.width,
    height: props.height,
  };

  if (images) {
    if (!state.est_introuvable) {
      return (
        <div>
          <img
            alt={state.imgIndex}
            style={style}
            onClick={handleClick}
            src={images[state.imgIndex]}
          />
        </div>
      );
    } else {
      return <div className={useStyles.root}> L'annonce n'existe plus</div>;
    }
  }
  return <div className={useStyles.root}> Pas d 'images disponibles...</div>;

}

Carousel.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  source: PropTypes.string.isRequired,
};

Carousel.defaultProps = {
  width: "220px",
  height: "auto",
};

export default Carousel;
