import React, { Component, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import style from '@mui/system/style';
import BarChartIcon from '@mui/icons-material/BarChart';
import { PG_URL } from '../../../../constantes/Utils';
import OutlinedButton from '../../../../template_components/OutlinedButton';

const styles = {
  formControlLabel: {
    "float": 'right',
    "position": 'relative',
    "padding": "0",
    "paddingRight": "16",
  },
  checkbox: {
    "padding": "6",
  },
};

const BulkUpdateStatsButton = () => {
  // constructor(props) {
  //   super(props);
  //   this.state = { 
  //     loading: false, 
  //     error: false,
  //     success: false,
  //     exist: true,
  //   };
  //   this.handleClick = this.handleClick.bind(this);
  //   this.handleChange = this.handleChange.bind(this);
  // }
  const [state, setState] = useState({
    loading: false,
    error: false,
    success: false,
    exist: true,
  });

  const handleClick = () => {
    setState((prevState) => ({ ...prevState, loading: true, error: false, success: false }));

    //const { exist } = this.state;
    const urlApi = `${PG_URL}/rpc/execute_stats`;

    const params = {
      need_search_ids: '{' + this.props.selectedIds.toString() + '}',
      exist: exist
    }

    const body = JSON.stringify(params);
    fetch(urlApi, { method: 'POST', headers: { 'Content-Type': 'application/json'/*, 'Prefer': 'params=single-object' */ }, body: body }).then(response => response.json())
      .then((res) => {
        document.querySelector('[title="Refresh"]').click();
      }).catch((e) => {
        console.error(e);
        setState((prevState) => ({ ...prevState, loading: false, error: true }));
      });
  }

  const handleChange = () => {
    setState((prevState) => ({ ...prevState, exist: !prevState.exist }));
  }


  // const { state, props } = this;
  // const { exist, loading, error, success } = state;
  // const { classes } = props;

  return (
    <div>
      <OutlinedButton
        icon={<BarChartIcon />}
        loading={state.loading}
        error={state.error}
        success={state.success}
        onClick={handleClick}
        label={'Rafraîchir les stats'}
        loadingLabel={'Actualisation des stats...'}
        successLabel={'Stats actualisées !'}
      />
      <FormControlLabel
        classes={styles.formControlLabel}
        control={
          <Checkbox
            classes={styles.checkbox}
            checked={state.exist}
            onChange={handleChange}
            color="primary"
          />
        }
        labelPlacement="start"
        label="Annonces existantes ?"
      />
    </div>
  );

}

export default BulkUpdateStatsButton;
