import React from 'react';
import { styled } from '@mui/system';
import {
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import CustomTextField from '../../template_components/CustomTextField';
import { useGetOne, useRecordContext } from 'react-admin';
import AnnoncesGarage from './components/AnnoncesGarage';
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: 'white',
  padding: 0,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  padding: 0,
}));

const AnnoncesGaragePanel = () => {
  const record = useRecordContext();

  return (
    <StyledAccordion>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />} aria-label="info">
        <StyledIconButton aria-label="info" disabled>
          <LocalCarWashIcon style={{ color: 'white' }} />
        </StyledIconButton>
        <Typography variant="h6" color="white">
          Garage
        </Typography>
      </StyledAccordionSummary>
      <AccordionDetails>
        <div>
          <CustomTextField title="Garage" value={record.json_propre.sure.garage} />
          <CustomTextField title="Code Postal" value={record.json_propre.sure.code_postal} />
          <CustomTextField title="Ville" value={record.json_propre.sure.ville} />
        </div>
        <AnnoncesGarage record={record} />
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default AnnoncesGaragePanel;

