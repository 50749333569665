import React from 'react'
import {
    Datagrid, DateField,
    ReferenceManyField,
    TextField,
    useRecordContext,
} from 'react-admin';
import PriceField from "../../template_components/PriceField";
import DistanceField from '../../template_components/DistanceField';
import SimpleArrayField from '../../template_components/SimpleArrayField';
// import NeedRowButtons from "../../needs/NeedRowButtons";
import ProjectNeedsShow from "./ProjectNeedsShow";

const ProjectNeedsList = () => {
    const record = useRecordContext();
    //console.log(record);
    return (
        <ReferenceManyField
            {...record}
            label=" "
            target="project_id"
            reference="needs"
            //source="id"
            resource={"needs"}
            //basePath={"needs"}
            sort={{
                field: 'row_updated',
                order: 'DESC',
            }}
            filter={{
                project_id: record.id,
            }}
        >
            <Datagrid 
                expand={<ProjectNeedsShow {...record}/>}

            >
                <TextField label="Nom" source="name"/>
                <TextField label="Projet" source="project_id"/>
                <PriceField label="Prix minimum" source="min_price"/>
                <PriceField label="Prix maximum" source="max_price"/>
                <DistanceField label="Kilométrage maximum" source="max_km"/>
                <SimpleArrayField label="Boîte de vitesses" source="gearbox"/>
                <SimpleArrayField label="Carburant" source="fuel"/>
                <SimpleArrayField label="Flags" source="segments"/>
                <SimpleArrayField label="Marques" source="brands"/>
                <SimpleArrayField label="Mots clés" source="keywords"/>
                <DateField label="Date" source="row_updated" showTime />
                {/* <NeedRowButtons record={record}/>  */}
            </Datagrid>
        </ReferenceManyField>
    )
}

export default ProjectNeedsList;
