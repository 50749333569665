import React from "react";
import { Show, TabbedShowLayout, useRecordContext, useListContext } from "react-admin";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import WeekendIcon from "@mui/icons-material/Weekend";
import SearchIcon from "@mui/icons-material/Search";
import BugReportIcon from "@mui/icons-material/BugReport";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ProjectTitle from "./ProjectTitle";
import ProjectActions from "./ProjectActions";
import ProjectUserTab from "./users/ProjectUserTab";
import ProjectRecapTab from "./recaps/ProjectRecapTab";
import ProjectStatusTab from "./status/ProjectStatusTab";
import ProjectAnswersTab from "./answers/ProjectAnswersTab";
import ProjectOffersTab from "./offers/ProjectOffersTab";
import ProjectActivitiesTab from "./activities/ProjectActivitiesTab";
import ProjectNeedsTab from "./needs/ProjectNeedsTab";
import ProjectScrapersTab from "./scrapers/ProjectScrapersTab";
import ProjectBestOffersTab from "./bestOffers/ProjectBestOffersTab";
import FindInPageIcon from '@mui/icons-material/FindInPage';

const ProjectShow = (props) => {

  const record = useRecordContext();
  const recordList = useListContext();

  // Ici nous allons console.info les props pour voir ce qu'elles contiennent
  console.info("-------------- <ProjectShow /> --------------")
  console.info("record: ", record);
  console.info("recordList: ", recordList);
  console.info("props: ", JSON.stringify(props));
  console.info("-------------- <ProjectShow /> --------------")
  const perms = localStorage.getItem("permissions");
  return (
    <Show title={<ProjectTitle />} actions={<ProjectActions {...props} />} sx={{ display: 'flex', flexDirection: 'column' }}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab icon={<AccountBoxIcon />} label="Informations Client">
          <ProjectUserTab />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab icon={<AccountBoxIcon />} label="Fiche Projet">
          <ProjectRecapTab />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab icon={<AccessibilityIcon />} label="Suivi Client">
          <ProjectStatusTab />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab icon={<AssignmentIcon />} label="Réponse Projet">
          <ProjectAnswersTab />
        </TabbedShowLayout.Tab>
        {perms == "admin" ? <TabbedShowLayout.Tab icon={<FindInPageIcon />} label="Besoins modèles">
          <ProjectNeedsTab />
        </TabbedShowLayout.Tab> : null}
        {perms == "admin" ? <TabbedShowLayout.Tab icon={<DirectionsCarIcon />} label="Annonces Assignées" >
          <ProjectOffersTab />
        </TabbedShowLayout.Tab> : null}
        <TabbedShowLayout.Tab icon={<WeekendIcon />} label="Activités">
          <ProjectActivitiesTab />
        </TabbedShowLayout.Tab>
        {perms == "admin" ? <TabbedShowLayout.Tab icon={<SearchIcon />} label="Recherches">
          <ProjectNeedsTab />
        </TabbedShowLayout.Tab> : null}
        {perms == "admin" ? <TabbedShowLayout.Tab icon={<BugReportIcon />} label="Scrapers">
          <ProjectScrapersTab />
        </TabbedShowLayout.Tab> : null}
        {perms == "admin" ? <TabbedShowLayout.Tab icon={<FavoriteIcon />} label="Recommandations">
          <ProjectBestOffersTab />
        </TabbedShowLayout.Tab> : null}
      </TabbedShowLayout>
    </Show>
  );
};

/*const ProjectShow = (props) => (
  <Show title={<ProjectTitle {...props} />} actions={<ProjectActions {...props} />} {...props}>
    <TabbedShowLayout>
      <ProjectUserTab />
      <ProjectRecapTab/>
      <ProjectStatusTab />
      <ProjectAnswersTab />
      <ProjectOffersTab {...props} />
      <ProjectActivitiesTab />
      <ProjectNeedsTab {...props} />
      <ProjectScrapersTab />
      <ProjectBestOffersTab />
    </TabbedShowLayout>
  </Show>
);*/

export default ProjectShow;
