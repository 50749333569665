import React from 'react';
import {
  Tab,
  ReferenceManyField,
  TextField,
  Datagrid,
  TabbedShowLayout,
  useRecordContext,
} from 'react-admin';
import AssignmentIcon from '@mui/icons-material/Assignment';

const ProjectAnswersTab = () => {
  const record = useRecordContext();
	return (
  <TabbedShowLayout.Tab icon={<AssignmentIcon />} label="Réponse projet" {...record}>
    <ReferenceManyField
      label=""
      source="id"
      target="projectid"
      reference="projects_answers"
      sort={{
        field: 'projectid',
        order: 'ASC',
      }}
      addLabel={false}
    >
      <Datagrid>
        <TextField label="Questions:" source="title" />
        <TextField label="Réponses:" source="answer" />
      </Datagrid>
    </ReferenceManyField>
  </TabbedShowLayout.Tab>
)
};

export default ProjectAnswersTab;
