import React, { Component } from 'react';
import {redirect} from 'react-router-dom';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { PG_URL } from '../../../constantes/Utils';
import FeedbackSnackbar from '../../../template_components/FeedbackSnackbar';

class DeleteProjectOfferButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			variant: '',
			message: '',
			duration: 1000,
			redirect: false,
		};
		this.handleClick = this.handleClick.bind(this)
	}

	renderRedirect = () => {
		if (this.state.redirect) {
			//return <Redirect push={true} to={`/vprojects_reconciliations/${this.props.project_id}/show/4`} />
			redirect(`/vprojects_reconciliations/${this.props.project_id}/show/4`);
		}
	}

	handleClick() {
		this.setState({ loading: true });

		const { props } = this;
    const { record, project_id } = props;

    const urlApi = `${PG_URL}/projects_reconciliations?id_project=eq.${project_id}&id_reconciliation=eq.${record.id}`

    fetch(urlApi, { method: 'DELETE' })
    .then((response) => {
      if (!response.ok) throw new Error(`Impossible de supprimer l'annonce théorique ${record.id} !`);
			window.location.reload();
			// this.setState({ redirect: true });
    })
    .catch((e) => {
      console.log(e)
      this.setState({ loading: false, variant: 'error', message: `Erreur lors de la suppression: ${e}`, duration: 2000 });
      setTimeout(() => { this.setState({ message: '' }) }, 2250);
    });
	}

	render() {
    const { state } = this;
    const { loading, message, duration, variant } = state;
		
		return (
      <>
        {this.renderRedirect()}
        {!loading ?
					<Button
						onClick={this.handleClick}
						style={{color: '#f44336'}}
					>
						<DeleteIcon color="error" />
					</Button>
        : 
          <Button disabled><CircularProgress size={24} color="inherit" /></Button>
				}
				{message !== '' ? <FeedbackSnackbar duration={duration} variant={variant} message={message} position="absolute" vertical="bottom" /> : null }
      </>
		)
	}
}

export default DeleteProjectOfferButton;