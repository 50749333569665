import React, { Fragment } from 'react';
import {
  EditButton,
  ShowButton,
} from 'react-admin';
import PublipostageButton from './components/PublipostageButton';
import DeleteProjectOfferButton from './components/DeleteProjectOfferButton';

const ProjectOffersRowButtons = props => (
	<Fragment>
    <PublipostageButton {...props} />
    <EditButton label="" {...props} basePath={"/reconciliation"} />
    <ShowButton label="" {...props} basePath={"/vreconciliation_checklist2pdf"} />
    <DeleteProjectOfferButton {...props} basePath={"/reconciliation"} />
	</Fragment>
);

export default ProjectOffersRowButtons;
