import React from 'react';
import { useRecordContext } from 'react-admin';
import { style } from '@mui/system';
import {
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Carousel from '../../template_components/Carousel';

const useStyles = style((theme) => ({
  summary: {
    backgroundColor: '#4caf50',
  },
  summaryTitle: {
    padding: 0,
    color: 'white',
  },
  iconButton: {
    marginRight: 20,
    padding: 0,
  },
}));

const AnnoncesPicturesPanel = () => {
  const classes = useStyles();
  const record = useRecordContext();
  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />} className={classes.summary} aria-label="info">
        <IconButton className={classes.iconButton} aria-label="info" disabled>
          <PhotoCameraIcon style={{ color: 'white' }} />
        </IconButton>
        <Typography variant="h6" color="primary" className={classes.summaryTitle}>
          Photos
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Carousel width={'100%'} record={record} source="json_propre.sure.images" />
      </AccordionDetails>
    </Accordion>
  );
};

export default AnnoncesPicturesPanel;

