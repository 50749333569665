/*
import React from 'react';
import { AutocompleteInput, SelectArrayInput, SelectInput, useChoicesContext, useInput, useRecordContext} from 'react-admin';

const SelectUniqueBrand = WrappedComponent => props => {
  const filteredChoices = UniqueBrand(props.choices);
  const newProps = { ...props };
  newProps.choices = filteredChoices;
  return (<WrappedComponent {...newProps} />);
};


const SelectArrayValue = WrappedComponent => ({classes, ...props}) => {
  const {
    // fetched data
    allChoices, // an array of the choices records, e.g. [{ id: 123, title: 'hello world' }, { ... }], both available and selected. 
    availableChoices, // an array of the available choices records, e.g. [{ id: 123, title: 'hello world' }, { ... }],. 
    selectedChoices, // an array of the selected choices records, e.g. [{ id: 123, title: 'hello world' }, { ... }],. 
    total, // the total number of results for the current filters, excluding pagination. Useful to build the pagination controls, e.g. 23      
    isFetching, // boolean that is true while the data is being fetched, and false once the data is fetched
    isLoading, // boolean that is true until the data is available for the first time
    error, // Will contain any error that occurred while fetching data
    // pagination
    page, // the current page. Starts at 1
    perPage, // the number of results per page. Defaults to 25
    setPage, // a callback to change the page, e.g. setPage(3)
    setPerPage, // a callback to change the number of results per page, e.g. setPerPage(25)
    hasPreviousPage, // boolean, true if the current page is not the first one
    hasNextPage, // boolean, true if the current page is not the last one
    // sorting
    sort, // a sort object { field, order }, e.g. { field: 'date', order: 'DESC' }
    setSort, // a callback to change the sort, e.g. setSort({ field: 'name', order: 'ASC' })
    // filtering
    filter, // The permanent filter values, e.g. { title: 'lorem', nationality: 'fr' }
    filterValues, // a dictionary of filter values, e.g. { title: 'lorem', nationality: 'fr' }
    displayedFilters, // a dictionary of the displayed filters, e.g. { title: true, nationality: true }
    setFilters, // a callback to update the filters, e.g. setFilters(filters, displayedFilters)
    showFilter, // a callback to show one of the filters, e.g. showFilter('title', defaultValue)
    hideFilter, // a callback to hide one of the filters, e.g. hideFilter('title')
    // misc
    resource, // the resource name, deduced from the location. e.g. 'posts'
    refetch, // callback for fetching the list data again
    source, // the name of the field containing the currently selected record(s).
} = useChoicesContext();
  const record = useRecordContext();
  const filteredChoices = UniqueValue(props.choices);
  const newProps = { ...props };
  newProps.choices = filteredChoices;
  return (<WrappedComponent {...newProps} />);
};
/*
const SelectArrayValue = props => {
  const { input } = useInput(props);
  const filteredChoices = UniqueValue(props.choices);
  newProps.choices = filteredChoices;
  return (<WrappedComponent {...input} />); 	
};


const SelectUniqueValue = WrappedComponent => ({classes, ...props}) => {
  const record = useRecordContext();
  const resource = useResourceContext();

  const filteredChoices = UniqueValue(props.choices);
  const newProps = { ...props };
  newProps.choices = filteredChoices;
  return (<WrappedComponent {...newProps} />);
};


const UniqueBrand = (array) => (
  (array ? array:[]).filter((elem, index, liste) => (
    liste.findIndex(c => c.marque === elem.marque) === index
  ))
);


const UniqueValue = (array) => {
  let values = [], res = [];
  (array ? array:[]).forEach((elem) => {
    if (!values.includes(elem.value.toLowerCase())) {
      values.push(elem.value.toLowerCase())
      res.push({id: elem.id, value: elem.value.toLowerCase()})
    }
  })
  return res
};

export const WrappedAutocompleteBrand = SelectUniqueBrand(AutocompleteInput);
export const WrappedSelectArrayValue = SelectArrayValue(SelectArrayInput);
//export const WrappedSelectUniqueValue = SelectArrayValue;

export const WrappedSelectUniqueValue = SelectUniqueValue(SelectInput);
*/

import React from 'react';
import { useInput } from 'react-admin';
import { AutocompleteInput, SelectArrayInput, SelectInput } from 'react-admin';

const SelectUniqueBrand = WrappedComponent => props => {
  const filteredChoices = UniqueBrand(props.choices);
  const newProps = { ...props };
  newProps.choices = filteredChoices;
  return <WrappedComponent {...newProps} />;
};

const SelectArrayValue = WrappedComponent => props => {
  const { input } = useInput(props);
  const filteredChoices = UniqueValue(props.choices);
  const newProps = { ...props };
  newProps.choices = filteredChoices;
  return <WrappedComponent {...newProps} {...input} />;
};
export default SelectArrayValue;

const SelectUniqueValue = WrappedComponent => props => {
  const { input } = useInput(props);
  const filteredChoices = UniqueValue(props.choices);
  const newProps = { ...props };
  newProps.choices = filteredChoices;
  return <WrappedComponent {...newProps} {...input} />;
};

const UniqueBrand = (array) => (
  (array ? array:[]).filter((elem, index, liste) => (
    liste.findIndex(c => c.marque === elem.marque) === index
  ))
);

const UniqueValue = (array) => {
  let values = [], res = [];
  (array ? array:[]).forEach((elem) => {
    if (!values.includes(elem.value.toLowerCase())) {
      values.push(elem.value.toLowerCase())
      res.push({id: elem.id, value: elem.value.toLowerCase()})
    }
  })
  return res
};

export const WrappedAutocompleteBrand = SelectUniqueBrand(AutocompleteInput);
export const WrappedSelectArrayValue = SelectArrayValue(SelectArrayInput);
export const WrappedSelectUniqueValue = SelectUniqueValue(SelectInput);

