import { redirectDocument, useNavigate } from "react-router";
import authProvider from "../authProvider";
import customFetch from "./customFetch";
import { INACTIVITY_TIME, PG_URL } from './Utils';
window.fetch = customFetch;


const setStorage = (k, v) => {
    if (k == 'JWT' && (v != localStorage.getItem('JWT') || localStorage.getItem('JWT') == null)) {
        console.log("Test event : ", k, v);
        localStorage.setItem(k, v);
        window.clearTimeout();
        window.setTimeout(() => {
            console.log("deconnexion, ", INACTIVITY_TIME, PG_URL);
            authProvider.logout();
            window.location.href = '/login';
        }, 1000 * INACTIVITY_TIME * 60);
    }
}
export default setStorage;
