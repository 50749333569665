import React, { useState } from 'react';
import {
    Tab,
    TabbedShowLayout,
    useRecordContext,
} from 'react-admin';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import TabItem from '@mui/material/Tab';
import SearchIcon from '@mui/icons-material/Search';
import ResearchList from '../../needs/researches/ResearchList';
import ProjectNeedsList from "./ProjectNeedsList";
import { useLocation, useParams } from 'react-router-dom';


const ProjectNeedsTab = ({ hasEdit, hascreate, hasList, hasShow }) => {
    const locate = useLocation();

    const initialState = () => {
        if (locate.state && locate.state.initial === 'besoins')
            return 2
        else
            return 1
    }
    const [value, setValue] = useState(initialState);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <TabbedShowLayout.Tab label="Recherches" icon={<SearchIcon />}>
            <ProjectNeedsList />
        </TabbedShowLayout.Tab>
    )
};

export default ProjectNeedsTab;
