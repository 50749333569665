import React from 'react'
//import Button from '@mui/material/Button'
import {IconButton,} from '@mui/material';
//import { withRouter } from 'react-router';
import { useNavigate } from 'react-router-dom';
/*import { withRouter } from './WithRouterFunction';

const BackButton = ({ history: { goBack }, children, ...props }) => (
  <IconButton {...props} onClick={goBack}>
    {children}
  </IconButton>
)

export default withRouter(BackButton) */

function BackButton({ children }) {
  let navigate = useNavigate();
  return (
    <IconButton onClick={() => navigate(-1)}>
      {children}
    </IconButton>
  );
}

export default BackButton;
