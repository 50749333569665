import React from 'react';
import PropTypes from 'prop-types';

const OperatorTitle = ({ record, edit, create }) => {
  if (record && record.project_id) {
		document.title = document.title.replace('Recherches', 'Besoins')
    if (!document.title.includes('[création]') && create) document.title += ' [création]';
    else if (document.title.split('-').length === 3 && record && !create) document.title += ` - ${record.name}`;
    else if (!document.title.includes('[édition]') && edit) document.title += ' [édition]';
    else if (!edit) document.title = document.title.replace('[édition]', '');
  } else {
		document.title = record
		? create
			? `Operator [création]`
			: `Operators - ${record.name}
				${edit ? ' [édition]' : ''}`
		: `Operators`
	}
	return (
	  <span>
	    {document.title}
	  </span>
  )
};

OperatorTitle.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
};

OperatorTitle.defaultProps = {
	edit: false,
	create: false,
};

export default OperatorTitle;
