import React from 'react';
import { style } from '@mui/system';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import { useRecordContext } from 'react-admin';

/*
**  JSONviewer component :
**    - Permet de convertir un JSON stocké en DB afin de l'afficher
**    - Gère le JSONb d'un objet de profondeur 3 max
*/

const styles = theme => ({
  div: {
    padding: 20,
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  chip: {
    margin: theme.spacing(1),
  },
});

const isEmpty = (obj) => {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}

const JSONviewer = props => {
	const rec = useRecordContext();

  const { record, classes } = props;
  const keys = Object.keys(rec);

  const details = keys.map(key => {
    const value = rec[key]
    if ((typeof value !== 'object' && value !== null) || (value === null && key==="prix")) {
      return <div key={key}>
          <Typography>
            <strong>{(key[0]||"").toUpperCase() + key.substring(1).split('_').join(' ')}</strong>
            {' '}
            :
            {' '}
            {!key.toLowerCase().includes("url") ? value : <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>}
          </Typography>
        </div>
    }
    return null;
  })

  const renderData = keys.map(key => {
    let value = rec[key]
    let renderValue = value

    if (Array.isArray(value) && value.length) {
      renderValue = value.map((elem, index) => {
        if (key.toLowerCase().includes("image")) return <img key={key+" "+index} src={elem} alt={"Caradisiac " + index} style={{ maxWidth: '300px' }} />
        return <Chip key={key+" "+index} label={elem} className={classes.chip} />
      })
    }

    else if (typeof value === 'object' && value !== null && !isEmpty(value)) {
      const subKeys = Object.keys(value);

      renderValue = subKeys.map(subKey => {
        let subValue = rec[key][subKey]

        if (typeof subValue === 'object' && subValue !== null) {
          subValue = Object.values(subValue).join(' ');
        }
        return (
          <div key={subKey}>
            <Typography>
              <strong>{(subKey[0]||"").toUpperCase() + subKey.substring(1).split('_').join(' ')}</strong>
              {' '}
              :
              {' '}
              {!subKey.toLowerCase().includes("url") ? subValue : <a href={subValue} target="_blank" rel="noopener noreferrer">{subValue}</a>}
            </Typography>
          </div>
        )
      })
    } else return null;

    return (
      <Grid key={key} item xs={6}>
        <Paper className={classes.paper}>
          <Typography variant="h6">{key.split('_').join(' ').toUpperCase()}</Typography>
          <hr />
          {renderValue}
        </Paper>
      </Grid>
    )
  })

  const renderDetails = <Grid key="details" item xs={6}>
      <Paper className={classes.paper}>
        <Typography variant="h6">DETAILS</Typography>
        <hr />
        {details}
      </Paper>
    </Grid>

  return (
    <div className={classes.div}>
      <Grid container spacing={24}>
        {renderDetails}
        {renderData}
      </Grid>
    </div>
  );
}

export default style(styles)(JSONviewer);
