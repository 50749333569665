import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ShowIcon from '@mui/icons-material/Visibility';

import { PG_URL } from '../../../constantes/Utils';

const style = {
  float: 'right',
  marginTop: '10px',
  marginRight: '10px',
};

function ShowGroupButton(props) {
  const { id } = props;
  const path = `#/vgroups/${id}/show`;

  return (
    <Button href={path} color="secondary" variant="outlined" style={style}>
      <ShowIcon />
      &nbsp;
      Voir
    </Button>
  );
}

ShowGroupButton.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

class AnnoncesGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jsonGroup: {},
    };
  }

  loadData = () => {
    const { record } = this.props;
    const { json_propre: json_propre } = record;

    fetch(`${PG_URL}/vgroups?id=eq.${json_propre.sure.siren}`)
      .then(response => response.json())
      .then(data => this.setState({ jsonGroup: data[0] }));
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.record !== prevProps.record) {
      this.loadData();
    }
  }

  render() {
    const { state } = this;
    const { jsonGroup } = state;

    if (jsonGroup && jsonGroup.name) {
      return (
        <div style={{ width: '100%' }}>
          <ShowGroupButton id={jsonGroup.id} />
          <Typography component="h2" variant="h5" gutterBottom>
            {jsonGroup.name}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            siren : {jsonGroup.id}
            ,&nbsp;
	    naf : {jsonGroup.naf}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            capital social : {jsonGroup.capital_social} €
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            blacklisté : {jsonGroup.black_list}
          </Typography>
        </div>
      );
    }
    return null;
  }
}

AnnoncesGroup.propTypes = {
  record: PropTypes.shape({}).isRequired,
};

export default AnnoncesGroup;
