import React from 'react';
import PropTypes from 'prop-types';
import { style } from '@mui/system';
import Typography from '@mui/material/Typography/Typography';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '0.9285714285714286rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1,
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  },
  body: {
    color: 'rgba(0, 0, 0, 0.87)',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    fontSize: '0.9285714285714286rem',
    fontWeight: 400,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  }
});

const CustomTextField = props => {
  const { title, value, classes, component } = props;

  return (
    <div className={classes.root}>
      <Typography variant="caption" className={classes.label}>
        {title}
      </Typography>
      <Typography className={classes.body} component={component}>
        {value}
      </Typography>
    </div>
  );
}

CustomTextField.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.node,
	])
};

CustomTextField.defaultProps = {
  value: '',
  title: '',
};

export default style(styles)(CustomTextField);
