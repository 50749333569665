import React from 'react';
import { useRecordContext } from 'react-admin';
import { style } from '@mui/system';
import {
  Typography,
  IconButton,
} from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';

import AnnoncesGroup from './components/AnnoncesGroup';

const useStyles = style((theme) => ({
  summary: {
    backgroundColor: '#4caf50',
  },
  summaryTitle: {
    padding: 0,
    color: 'white',
  },
  iconButton: {
    marginRight: 20,
    padding: 0,
  },
}));

const AnnoncesGroupPanel = () => {
  const classes = useStyles();
  const record = useRecordContext();
  return (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />} className={classes.summary} aria-label="info">
      <IconButton className={classes.iconButton} aria-label="info" disabled>
        <LocalCarWashIcon style={{ color: 'white' }} />
      </IconButton>
      <Typography variant="h6" color="primary" className={classes.summaryTitle}>
        Groupe
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <AnnoncesGroup record={record} />
    </AccordionDetails>
  </Accordion>
);
}
export default AnnoncesGroupPanel;
