import { convertLegacyDataProvider } from 'react-admin';
import { PG_URL } from './constantes/Utils';
import postgrestClient from './postgrest_dataprovider';
import { base64 } from 'js-md5';
import setStorage from './constantes/fetchPolyfill';
import CryptoJS from 'crypto-js';

function sha256Hash(inputString) {
    return CryptoJS.SHA256(inputString).toString(CryptoJS.enc.Hex);
}

const dataProviderRest = convertLegacyDataProvider(postgrestClient(PG_URL));
//let currentUser = null;
const authProvider = {
    login: async ({ username, password }) => {

        const response = await fetch(`${PG_URL}/rpc/authenticate_and_generate_jwt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "p_username": username, "p_password": sha256Hash(password) })
        })
            .then(data => data.text())
            .then(res => {
                let returned_value = res.replace('"', '').replace('"', '');
                console.log(returned_value);
                return returned_value;
            }).catch(error => console.log(error));

        if (response != null) {
            const userData = response.split('.')[1];
            const jsonUserData = JSON.parse(atob(userData));
            localStorage.setItem('currentUser', JSON.stringify(jsonUserData));
            localStorage.setItem('username', jsonUserData._user);
            localStorage.setItem('email', jsonUserData._email);
            localStorage.setItem('permissions', jsonUserData._role);
            setStorage('JWT', response);
            // localStorage.setItem('JWT', response);
            return Promise.resolve(jsonUserData);
        } else {
            return Promise.reject(new Error('Identifiants incorrects'));
        }
    },
    logout: () => {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('username');
        localStorage.removeItem('permissions');
        localStorage.removeItem('email');
        localStorage.removeItem('JWT');
        return Promise.resolve();
    },
    checkAuth: () => {
        if (localStorage.getItem('username')) {
            return Promise.resolve();
        } else {
            return Promise.reject("pas de compte connecté");
        }
    },
    checkError: (error) => {
        console.log("debug error!")
        if (error) {
            const status = error.status;
            if (status === 401 || status === 403) {
                localStorage.removeItem('username');
                return Promise.reject("Refus du serveur");
            }
            if (error) {
                console.log("ERROR :::", status)
            }
        }


        //other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () => {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log(currentUser);
        if (currentUser) {
            return Promise.resolve({
                id: currentUser._user_id,
                //fullName: currentUser.firstname + " \"" + currentUser.username + "\" " + currentUser.lastname, A corriger
                fullName: currentUser._user,
            });
        } else {
            return Promise.resolve(null);
        }
    },
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject("Pas de perms");
    },
};

export default authProvider;