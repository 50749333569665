import React from 'react';
import {
  Show,
  SimpleShowLayout,
  ReferenceArrayField,
  Datagrid,
  TextField,
  TabbedShowLayout,
  useRecordContext,
} from 'react-admin';
import SimpleArrayField from '../../template_components/SimpleArrayField';
import PriceField from '../../template_components/PriceField';
import DistanceField from '../../template_components/DistanceField';

const ProjectScrapersShow = () => {
  const record = useRecordContext();

  return (
    <Show>
	<SimpleShowLayout record={record}>
        <ReferenceArrayField
          label={false}
          source="researches"
          reference="needs_search"
          sort={{
            field: 'marque',
            order: 'ASC',
          }}
        >
          <Datagrid>
            <PriceField label="Prix minimum" source="prixmin" />
            <PriceField label="Prix maximum" source="prixmax" />
            <TextField label="Marque" source="marque" />
            <TextField label="Gamme" source="modele" />
            <SimpleArrayField label="Boîte de vitesses" source="boite" />
            <SimpleArrayField label="Carburant" source="carburant" />
            <SimpleArrayField label="Mots clés" source="keywords" />
            <TextField label="Année de mise en circulation" source="annee" />
            <DistanceField label="Kilométrage maximum" source="max_km" />
          </Datagrid>
        </ReferenceArrayField>
	</SimpleShowLayout>
    </Show>
  );
};

export default ProjectScrapersShow;
